/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useLayoutEffect} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
// import {getCSSVariableValue} from '../../../assets/ts/_utils'
// import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  cardName: any
  values: any
  values2: any
  chartName: string
  chartCategories: []
  chartData: []
  yaxisHeight: number
  statusnow: boolean
  statusnow2: boolean

}
const CURRENCY_APP = process.env.REACT_APP_CURRENCY
const INFINITE_ROWS = process.env.REACT_APP_INFINITE_ROWS

const addCommas = (num: any) => num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';
const ItemsSummary: React.FC<Props> = ({
  className,
  chartColor,
  chartHeight,
  cardName,
  values,
  values2,
  chartName,
  chartCategories,
  chartData,
  yaxisHeight,
  statusnow,
  statusnow2
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, chartName, chartData, chartCategories, yaxisHeight, statusnow, statusnow2 ))
    if (chart) {  
 
      chart.render()
    }

    return chart
  }

  useLayoutEffect(() => {
  
   
    if(statusnow && chartData.length > 0){
    
    
    
    const chart = refreshChart();
    
    return () => {
      if (chart) {
      
        chart.destroy()
      }
     
     
    }

   
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode, statusnow])

  useLayoutEffect(() => {

    if(statusnow2 && chartData.length > 0){
   
    refreshChart()?.updateOptions({
      series: [
        {
         
          data: chartData
        }
      ],
      xaxis: {
        categories: chartCategories,
        labels: {
          show: false
        }
      
      },
      yaxis: {
        show: false,
        min: 0,
        max: yaxisHeight * 1.1,
        axisTicks: {
          show: false,
        },
        labels: {
          show: false
        }
     
      }
    })
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusnow2])








  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      
      <div className='card-body d-flex flex-column p-0'>
        {/* begin::Stats */}
        <div className='flex-grow-1 card-p pb-0'>
          <div className='d-flex flex-stack flex-wrap'>
            <div className='me-2 mb-3'>
              <a href='#' className='text-dark fw-bolder fs-3'>
                {cardName}
              </a>
            </div>
            {values}
            {/* <div className={`fw-bold fs-3 text-${chartColor}`}>$24,500</div> */}
          </div>

          <div>{values2}</div>
        </div>
        {/* end::Stats */}

        {/* begin::Chart */}
        <div ref={chartRef} className='mixed-widget-7-chart card-rounded-bottom'></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string, chartName: string, chartData: (number)[], chartCategories: [], yaxisHeight: number, statusnow: boolean, statusnow2: boolean): ApexOptions => {
  const labelColor = getCSSVariableValue('--kt-gray-800')
  const strokeColor = getCSSVariableValue('--kt-gray-300')
  const baseColor = getCSSVariableValue('--kt-' + chartColor)
  const lightColor = getCSSVariableValue('--kt-' + chartColor + '-light')
  

 
  return {
    series: [
      {
        name: chartName,
        data: chartData,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: chartCategories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    
    yaxis: {
      min: 0,
      max: yaxisHeight * 1.1,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        
        },
      },
      
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return  `${addCommas(Number(val).toFixed(2))} ${CURRENCY_APP}`
        },
      },
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      strokeColors: [baseColor],
      strokeWidth: 3,
    },
  }
}

export {ItemsSummary}
