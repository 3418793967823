import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
// Assuming API_URL2 is not used for the expenses operations based on your initial examples

// Expenses
export function getExpenses(start_limit: number, pagination_size: number, itemto_order: string, desorasc: string, search_term: string, user_role_id: number, user_designation: number) {
  return axios.get(`${API_URL}/expenses_get`, {
    params: {
      start_limit,
      pagination_size,
      itemto_order,
      desorasc,
      search_term,
      user_role_id,
      user_designation,
    },
  });
}

export function createExpense(expenseData: any) {
  return axios.post(`${API_URL}/expenses_add`, expenseData);
}

export function updateExpense(expenseData: any) {
  return axios.put(`${API_URL}/expenses_update`, expenseData);
}

export function deleteExpense(expense_id: number) {
  return axios.delete(`${API_URL}/expenses_delete`, { params: { expense_id } });
}
