import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'

import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import Home from  '../../Signalenergy/dashboard/Home'
//inventory_management
import InventoryDashboard from '../../Signalenergy/inventory_management/InventoryDashboard'
import InventoryBrands from '../../Signalenergy/inventory_management/inventory_class/inventory_brands/InventoryBrands'
import InventoryPackaging from '../../Signalenergy/inventory_management/inventory_class/inventory_packaging/InventoryPackaging'
import InventoryCategories from '../../Signalenergy/inventory_management/inventory_class/inventory_categories/InventoryCategories'
import InventoryRequests from '../../Signalenergy/inventory_management/stock_operations/inventory_requests/InventoryRequests'
//inventory_management

//cs_managemen
import Clients from '../../Signalenergy/cs_management/cs_relations/clients/Clients';
//cs_management

//financial_management
import Expenses from "../../Signalenergy/finance_management/finance_operations/expenses/Expenses";
import Invoices from "../../Signalenergy/finance_management/finance_operations/invoices/Invoices"
//financial_management
import Norights from "../../Signalenergy/norights/Home"

//project_management
import ProjectDashboard from "../../Signalenergy/project_management/ProjectDashboard"
import ProjectCategories from "../../Signalenergy/project_management/project_class/project_categories/ProjectCategories"
import ProjectInventory from "../../Signalenergy/project_management/project_class/project_inventory/ProjectInventory"

//project_management

//sales_and_marketing
import Quotations from "../../Signalenergy/sales_marketing/sm_operations/quotations/Quotations"
import CreateQuotation from '../../Signalenergy/sales_marketing/sm_operations/quotations/CreateQuotation'


//sales_and_marketing

const PrivateRoutes = () => {
  
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/home' />} />
        {/* Pages */}
        <Route path='home/*' element={<Home  />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='inventory_management/inventory_dashboard' element={<InventoryDashboard />} />
        <Route path='inventory_management/home/inventory_class/inventory_categories' element={<InventoryCategories />} />
        <Route path='inventory_management/home/inventory_class/inventory_brands' element={<InventoryBrands />} />
        <Route path='inventory_management/home/inventory_class/inventory_packaging' element={<InventoryPackaging/>} />
        <Route path='inventory_management/stock_operations/inventory_requests' element={<InventoryRequests/>} />
       {/* cs_management */}
        <Route path='cs_management/cs_relations/clients' element={<Clients/>} />
        {/* cs_management */}
       
        {/*project_management*/}

        <Route path='project_management/project_dashboard' element={<ProjectDashboard/>} />
        <Route path='project_management/project_class/project_categories' element={<ProjectCategories/>} />
        <Route path='project_management/project_class/project_inventory' element={<ProjectInventory/>} />
        {/*project_management*/}

        {/* financial_management*/}
        <Route path='finance_management/finance_operations/expenses' element={<Expenses/>} />
        <Route path='financial_management/finance_operations/invoices' element={<Invoices/>} />
        {/* financial_management*/}
       {/* sales_and_marketing */}
       <Route path='sales_marketing/sm_operations/quotations' element={<Quotations/>} />
       <Route path='sales_marketing/sm_operations/createquotation' element={<CreateQuotation/>} />
        {/* sales_and_marketing */}


        <Route path='norights' element={<Norights />} />
        
       
     
      
       
        
        
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}



export {PrivateRoutes}
