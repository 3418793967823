import {createInventory, deleteInventory, getInventory, updateInventory} from '../../apis/inventory_management/Loadallinventory'
import { useQuery ,useMutation} from '@tanstack/react-query'



export const useInventory = (start_limit: number, pagination_size: number, itemto_order: string, desorasc: string, search_term: string,  user_role_id : number,  user_designation: number) => useQuery(
 {queryKey: ['inventory', start_limit, pagination_size],

 queryFn: () => getInventory(start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id,  user_designation), 
 placeholderData: (previousData, previousQuery) => previousData,
 staleTime: 5 * 60 * 1000, 
 
 
},
)



export const useAddInventory = (inventroyadded: any) => useMutation(
  
 { mutationFn:() => {
    return createInventory(inventroyadded)
  
}
 }
)

export const useDeleteInventory = (inventorytodelete: number) => useMutation(
  {
    mutationFn:() => {
    return deleteInventory(inventorytodelete)
  }
}
)


export const useUpdateInventory = (inventorytoupdate: any) => useMutation(
  {
    mutationFn: () => {
    return updateInventory(inventorytoupdate)
  }
}
)

