import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const API_URL2 = process.env.REACT_APP_API_URL_SOCKET

//inventory
export function getInventory(start_limit: number, pagination_size: number, itemto_order: string, desorasc: string, search_term: string,  user_role_id : number,  user_designation: number) {

  return axios.get(`${API_URL}/inventorymanagement_get`, { params: {start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id,  user_designation}})
}

export function createInventory(inventoryadded: any) {
  return axios.post(`${API_URL}/inventorymanagement_add`, inventoryadded)
}

//inventory

//delete inventory item
export function deleteInventory(inventorytodelete: number) {
  return axios.delete(`${API_URL}/inventorymanagement_delete`, {params:{inventorytodelete}})
}
//delete inventory item

export function updateInventory (inventorytoupdate: any) {

  return axios.put(`${API_URL}/inventorymanagement_update`, inventorytoupdate)
}


