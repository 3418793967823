import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const API_URL2 = process.env.REACT_APP_API_URL_SOCKET

// Packagings
export function getPackagings(start_limit: number, pagination_size: number, itemto_order: string, desorasc: string, search_term: string, user_role_id: number, user_designation: number) {
  return axios.get(`${API_URL}/packagings_get`, { params: {start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id, user_designation}});
}

export function createPackagings(packagingsadded: any) {
  return axios.post(`${API_URL}/packagings_add`, packagingsadded);
}

export function deletePackagings(packaging_id: number) {
  return axios.delete(`${API_URL}/packagings_delete`, {params: {packaging_id}});
}

export function updatePackagings(packagingstoupdate: any) {

  return axios.put(`${API_URL}/packagings_update`, packagingstoupdate);
}