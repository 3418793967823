import { useQuery, useMutation } from '@tanstack/react-query';
import { createProjectInventory, deleteProjectInventory, getProjectInventory, updateProjectInventory } from '../../apis/project_management/Loadallprojectinventory';

// Use Project Inventory Records
export const useProjectInventory = (
  start_limit: number,
  pagination_size: number,
  itemto_order: string,
  desorasc: string,
  search_term: string, // Assuming filtering by project_id if necessary
  user_role_id: number,
  user_designation: number
) => useQuery({
  queryKey: ['projectInventory', start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id, user_designation],
  queryFn: () => getProjectInventory(start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id, user_designation),
  staleTime: 5 * 60 * 1000, // 5 minutes
});

// Add Project Inventory Record
export const useAddProjectInventory = (projectInventoryAdded: any) => useMutation({
  mutationFn: () => createProjectInventory(projectInventoryAdded)
});

// Delete Project Inventory Record
export const useDeleteProjectInventory = (projectInventoryToDelete: number) => useMutation({
  mutationFn: () => deleteProjectInventory(projectInventoryToDelete)
});

// Update Project Inventory Record
export const useUpdateProjectInventory = (projectInventoryToUpdate: any) => useMutation({
  mutationFn: () => updateProjectInventory(projectInventoryToUpdate)
});
