import { useQuery, useMutation } from '@tanstack/react-query';
import { getPackagings, createPackagings, deletePackagings, updatePackagings } from '../../apis/inventory_management/Loadallpackagings'; // Adjust import path as necessary

export const usePackagings = (
  start_limit: number,
  pagination_size: number,
  itemto_order: string,
  desorasc: string,
  search_term: string,
  user_role_id: number,
  user_designation: number
) => useQuery({
  queryKey: ['packagings', start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id, user_designation],
  queryFn: () => getPackagings(start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id, user_designation),
  placeholderData: (previousData: any) => previousData,
  staleTime: 5 * 60 * 1000,
});

export const useAddPackaging = (packagingAdded: any) => useMutation({
  mutationFn: () => createPackagings(packagingAdded)
});

export const useDeletePackaging = (packaging_id: number) => useMutation({
  mutationFn: () => deletePackagings(packaging_id)
});

export const useUpdatePackaging = (packagingToUpdate: any) => useMutation({
  mutationFn: () => updatePackagings(packagingToUpdate)
});
