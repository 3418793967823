import React, {useEffect, useState, Dispatch, SetStateAction, useRef} from 'react'
import ReactPaginate from 'react-paginate'
import {Mainsubtable} from '../Mainsubtable'
import {Tableloading} from '../Tableloading'
import styled from 'styled-components'

import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  expandRows,
  flexRender,
  Column,
  Table,
  ExpandedState,
  getFilteredRowModel,
  getExpandedRowModel,
  ColumnDef,
  SortingState,
} from '@tanstack/react-table'
import {Tableloading2} from '../Tableloading2'
import {KTSVG} from '../../../_metronic/helpers'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { useReactToPrint } from 'react-to-print'
import { Mainsubtableprintquotations } from './Mainsubtableprintquotations'

dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(localizedFormat)

type Props = {
  tabledata: any
  columndata: any
  columndata2: any
 

}
const API_URL = process.env.REACT_APP_API_URL
const CURRENCY_APP = process.env.REACT_APP_CURRENCY
const addCommas = (num: any) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
const Maintableprintquotations: React.FC<Props> = ({
  tabledata,
  columndata,



  
  columndata2,
}) => {
  const pageSizes: number[] = [10, 20, 30, 40, 50]
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [expanded, setExpanded] = React.useState<ExpandedState>({})
  const [sorting, setSorting] = React.useState<SortingState>([])
  const componenttoprint = useRef<any | null>(null)
  const canRowExpand = (row: any): boolean => {
    return true
  }
  const tableInstance: any = useReactTable({
    data: tabledata,
    columns: columndata,
    state: {
      expanded,
      sorting,
    },
 
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    getSubRows: (row: any) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: true,
    manualPagination: true,
    manualSorting: true,
    
    manualExpanding: true, // Enable manual expansion
    getRowCanExpand: canRowExpand,
    // Additional configuration as needed...
  })


  return (
    <>
      <div className='table-responsive'>
        <table className='table table-hover table-rounded border border-gray-300 gy-5 gs-5'>
          <thead>
            {tableInstance.getHeaderGroups().map((headerGroup: any) => (
              <tr
                key={headerGroup.id}
                className='fw-bolder fs-6 text-gray-800 border-bottom-1 border-gray-300 text-uppercase'
              >
                {headerGroup.headers.map((header: any) => (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    className='cursor-pointer select-none'
                    style={{width: header.column.columnDef.meta?.width}}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}

                    {{
                      asc: (
                        <>
                          {' '}
                          <span className='svg-icon svg-icon-primary svg-icon-1x'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                            >
                              <g fill='none' fillRule='evenodd'>
                                <path d='M0 0L24 0 24 24 0 24z'></path>
                                <path
                                  fill='#009ef7'
                                  fillRule='nonzero'
                                  d='M17.293 8.293a1 1 0 111.414 1.414l-6 6a1 1 0 01-1.383.03l-6-5.5a1 1 0 111.352-1.474l5.294 4.853 5.323-5.323z'
                                ></path>
                              </g>
                            </svg>
                          </span>
                        </>
                      ),
                      desc: (
                        <>
                          {' '}
                          <span className='svg-icon svg-icon-primary svg-icon-1x'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                            >
                              <g fill='none' fillRule='evenodd'>
                                <path d='M0 0L24 0 24 24 0 24z'></path>
                                <path
                                  fill='#009ef7'
                                  fillRule='nonzero'
                                  d='M6.707 15.707a1 1 0 11-1.414-1.414l6-6a1 1 0 011.383-.03l6 5.5a1 1 0 11-1.352 1.474l-5.294-4.853-5.323 5.323z'
                                ></path>
                              </g>
                            </svg>
                          </span>
                        </>
                      ),
                    }[header.column.getIsSorted() as string] ?? null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            
              <>
                {tableInstance.getRowModel().rows.map((row: any) => (
                  // Use React.Fragment to wrap adjacent JSX elements
                  <React.Fragment key={row.id}>
                    <tr>
                      {row.getVisibleCells().map((cell: any) => (
                        <td key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                    { row.original.subRows.length > 0 && (
                      <tr>
                        {/* Make sure to adjust the colSpan to match the number of columns in your table */}

                        <td colSpan={tableInstance.getHeaderGroups()[0].headers.length}>
                          <Mainsubtableprintquotations
                            tabledata2={row.original.subRows}
                            columndata2={columndata2}
                          />
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </>
            
          </tbody>
        </table>
      </div>
      

    
    </>
  )
}

export {Maintableprintquotations}
