import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const API_URL2 = process.env.REACT_APP_API_URL_SOCKET

//clients
export function getClients(start_limit: number, pagination_size: number, itemto_order: string, desorasc: string, search_term: string,  user_role_id : number,  user_designation: number) {

  return axios.get(`${API_URL}/clients_get`, { params: {start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id,  user_designation}})
}

export function createClients(clientsadded: any) {
  return axios.post(`${API_URL}/clients_add`, clientsadded)
}

//clients

//delete clients item
export function deleteClients(clientstodelete: number) {
  return axios.delete(`${API_URL}/clients_delete`, {params:{clientstodelete}})
}
//delete clients item

export function updateClients (clientstoupdate: any) {

  return axios.put(`${API_URL}/clients_update`, clientstoupdate)
}

