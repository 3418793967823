import React, {useEffect, useState, Dispatch, SetStateAction} from 'react'
import ReactPaginate from 'react-paginate'
import {Mainsubtable} from '../Mainsubtable'
import {Tableloading} from '../Tableloading'
import styled from 'styled-components'

import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  expandRows,
  flexRender,
  Column,
  Table,
  ExpandedState,
  getFilteredRowModel,
  getExpandedRowModel,
  ColumnDef,
  SortingState,
} from '@tanstack/react-table'
import {Tableloading2} from '../Tableloading2'
import {KTSVG} from '../../../_metronic/helpers'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import localizedFormat from 'dayjs/plugin/localizedFormat'


dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(localizedFormat)

type Props = {
  tabledata: any
  columndata: any
  table_status: any
  table_success: any
  table1loaded: boolean | undefined
  movetofirstpage: boolean | undefined
  tablelength: number
  start_limit: number
  pagination_size: number
  pageCount: number
  setServersort: Dispatch<SetStateAction<any | undefined>>
  setPagination_size: Dispatch<SetStateAction<any | undefined>>
  setPageCount: Dispatch<SetStateAction<any | undefined>>
  setMovetofirstpage: Dispatch<SetStateAction<any | undefined>>
  paymenttypedetails?: (row: any) => void
  setStart_limit: Dispatch<SetStateAction<any | undefined>>
}
const API_URL = process.env.REACT_APP_API_URL

const Maintableclients: React.FC<Props> = ({
  tabledata,
  columndata,

  table1loaded,
 
  start_limit,
  movetofirstpage,
  paymenttypedetails,
  setStart_limit,
  tablelength,
  pagination_size,
  setPagination_size,
  setMovetofirstpage,
  pageCount,
  setPageCount,
  setServersort,
  table_status,
  table_success,
}) => {
  const pageSizes: number[] = [10, 20, 30, 40, 50]
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [expanded, setExpanded] = React.useState<ExpandedState>({})
  const [sorting, setSorting] = React.useState<SortingState>([])
  const canRowExpand = (row: any): boolean => {
    return true
  }
  const tableInstance: any = useReactTable({
    data: tabledata,
    columns: columndata,
    state: {
      expanded,
      sorting,
    },
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    getSubRows: (row: any) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: true,
    manualPagination: true,
    manualSorting: true,
    pageCount: pageCount,
    manualExpanding: true, // Enable manual expansion
    getRowCanExpand: canRowExpand,
    // Additional configuration as needed...
  })

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * pagination_size) % tablelength

    setCurrentPage(event.selected)
    setStart_limit(newOffset)
    setExpanded({})
  }

  useEffect(() => {
    if (movetofirstpage) {
      handlePageClick({selected: 0})
      setMovetofirstpage(false)
    }
  }, [movetofirstpage])

  const handlePageSizeChange = (e: any) => {
    setPagination_size(Number(e.target.value))

    setPageCount(Math.ceil(tablelength / Number(e.target.value)))

    handlePageClick({selected: 0})
    setMovetofirstpage(false)
  }

  useEffect(() => {
    if (tablelength > 0) {
      handlePageClick({selected: 0})

      setPageCount(Math.ceil(tablelength / pagination_size))
      setMovetofirstpage(false)
    } else {
      setPageCount(0)
    }
  }, [tablelength])

  useEffect(() => {
    setServersort(sorting)
  }, [sorting])

  return (
    <>
      <div className='table-responsive'>
        <table className='table table-hover table-rounded border border-gray-300 gy-5 gs-5'>
          <thead>
            {tableInstance.getHeaderGroups().map((headerGroup: any) => (
              <tr
                key={headerGroup.id}
                className='fw-bolder fs-6 text-gray-800 border-bottom-1 border-gray-300 text-uppercase'
              >
                {headerGroup.headers.map((header: any) => (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    className='cursor-pointer select-none'
                    style={{width: header.column.columnDef.meta?.width}}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}

                    {{
                      asc: (
                        <>
                          {' '}
                          <span className='svg-icon svg-icon-primary svg-icon-1x'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                            >
                              <g fill='none' fillRule='evenodd'>
                                <path d='M0 0L24 0 24 24 0 24z'></path>
                                <path
                                  fill='#009ef7'
                                  fillRule='nonzero'
                                  d='M17.293 8.293a1 1 0 111.414 1.414l-6 6a1 1 0 01-1.383.03l-6-5.5a1 1 0 111.352-1.474l5.294 4.853 5.323-5.323z'
                                ></path>
                              </g>
                            </svg>
                          </span>
                        </>
                      ),
                      desc: (
                        <>
                          {' '}
                          <span className='svg-icon svg-icon-primary svg-icon-1x'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                            >
                              <g fill='none' fillRule='evenodd'>
                                <path d='M0 0L24 0 24 24 0 24z'></path>
                                <path
                                  fill='#009ef7'
                                  fillRule='nonzero'
                                  d='M6.707 15.707a1 1 0 11-1.414-1.414l6-6a1 1 0 011.383-.03l6 5.5a1 1 0 11-1.352 1.474l-5.294-4.853-5.323 5.323z'
                                ></path>
                              </g>
                            </svg>
                          </span>
                        </>
                      ),
                    }[header.column.getIsSorted() as string] ?? null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table1loaded ? (
              <>
                <tr>
                  <td colSpan={tableInstance.getHeaderGroups()[0].headers.length}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center m-5'>
                      <Tableloading />
                    </div>
                  </td>
                </tr>
              </>
            ) : table_success && table_status === 'success' && tabledata.length > 0 ? (
              <>
                {tableInstance.getRowModel().rows.map((row: any) => (
                  // Use React.Fragment to wrap adjacent JSX elements
                  <React.Fragment key={row.id}>
                    <tr>
                      {row.getVisibleCells().map((cell: any) => (
                        <td key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                    {row.getIsExpanded() && (
                      <tr>
                        {/* Make sure to adjust the colSpan to match the number of columns in your table */}

                        <td colSpan={tableInstance.getHeaderGroups()[0].headers.length}>
                          <div className={`card`}>
                            {/* begin::Header */}
                            <div className='card-header border-0'>
                              <h3 className='card-title fw-bolder text-dark'>
                                More Details
                              </h3>
                              <div className='card-toolbar'>
                                {/* begin::Menu */}
                                {/* <button
                                  type='button'
                                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                                  data-kt-menu-trigger='click'
                                  data-kt-menu-placement='bottom-end'
                                  data-kt-menu-flip='top-end'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen024.svg'
                                    className='svg-icon-2'
                                  />
                                </button> */}

                                {/* end::Menu */}
                              </div>
                            </div>
                            {/* end::Header */}
                            {/* begin::Body */}
                            <div className='card-body pt-2'>
                              {/* begin::Item */}
                              <div className='d-flex align-items-center mb-8'>
                                {/* begin::Bullet */}
                                <span className='bullet bullet-vertical h-40px bg-info me-5'></span>
                                {/* end::Bullet */}

                                {/* begin::Description */}
                                <div className='flex-grow-1'>
                                  <span className='text-gray-800 fw-bold fs-6'>
                                    Constact Person :{' '}
                                    <span className='text-primary'>{row.original.contact_person}</span>
                                  </span>
                                  <span className='text-muted fw-semibold d-block'>
                                  Available

                                  </span>
                                </div>
                                {/* end::Description */}
                                <span>{}</span>
                              </div>
                              {/* end:Item */}
                              {/* begin::Item */}
                              
                                <div
                                  className='d-flex align-items-center bg-light-warning rounded p-5 w-100 fs-6 fw-darker'
                              
                                >
                                  
                                 {row.original.details}
                                
                                </div>
                              
                              {/* end:Item */}
                            </div>
                            {/* end::Body */}
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </>
            ) : tabledata.length === 0 ? (
              <>
                <tr>
                  <td colSpan={tableInstance.getHeaderGroups()[0].headers.length}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center m-5'>
                      <Tableloading2 />
                    </div>
                  </td>
                </tr>
              </>
            ) : null}
          </tbody>
        </table>
      </div>

      <div className='row'>
       
      <>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
              <div className='col-4 mb-2 mt-3'>
                <select
                  onChange={(e: any) => handlePageSizeChange(e)}
                  className='form-select form-select-solid'
                  aria-label='Select example'
                  value={pagination_size}
                >
                  {pageSizes.map((size, key3) => (
                    <option key={key3 + Date.now()} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <div className='mb-2'>
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>'
                  onPageChange={handlePageClick}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={3}
                  pageCount={pageCount}
                  forcePage={pageCount > 0 ? currentPage : -1}
                  previousLabel='<'
                  containerClassName='pagination'
                  pageClassName='page-item'
                  pageLinkClassName='page-link'
                  activeClassName='page-item active'
                  activeLinkClassName='page-item'
                  previousClassName='page-item previous'
                  previousLinkClassName='page-link'
                  nextClassName='page-item next'
                  nextLinkClassName='page-link'
                  disabledLinkClassName='page-item previous disabled'
                />
              </div>
            </div>
          </>
       
      </div>
    </>
  )
}

export {Maintableclients}
