import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

// Project Inventory Records
export function getProjectInventory(start_limit: number, pagination_size: number, itemto_order: string, desorasc: string, search_term: string, user_role_id: number, user_designation: number) {
  return axios.get(`${API_URL}/project_inventory_get`, {
    params: {
      start_limit,
      pagination_size,
      itemto_order,
      desorasc,
      search_term,
      user_role_id,
      user_designation,
    },
  });
}

export function createProjectInventory(projectInventoryData: any) {
  return axios.post(`${API_URL}/project_inventory_add`, projectInventoryData);
}

export function updateProjectInventory(projectInventoryData: any) {
  return axios.put(`${API_URL}/project_inventory_update`, projectInventoryData);
}

export function deleteProjectInventory(id: number) {
  return axios.delete(`${API_URL}/project_inventory_delete`, { params: { id } });
}
