import React, {FC, forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {jsPDF} from 'jspdf'
import autoTable from 'jspdf-autotable'
import {KTSVG} from '../../../../../_metronic/helpers'

// Assuming `backgroundImage` is a valid base64 or URL to the image
import {
  Emailicon,
  Locationicon,
  Phoneicon,
  Websiteicon,
  backgroundImage,
  logoimage,
} from '../../../../helpers/images/Signalenergylogo'
import {
  Poppinsbold,
  Poppinsitalic,
  Poppinslight,
  Poppinsnormal,
} from '../../../../helpers/fonts/Poppins'
import dayjs from 'dayjs'

type Props = {
  show: boolean
  handleClose: () => void
  ref: any
  tabledata: any
  newSubRowDetail: any
  rowIdsub: any
  showDetails: boolean
  showDetails2: boolean
  setLoadingpreview: any
  formik: any
}
const addCommas = (num: any) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
const CURRENCY_APP = process.env.REACT_APP_CURRENCY
export const Quotationview: FC<Props> = forwardRef(
  (
    {
      show,
      handleClose,
      tabledata,
      newSubRowDetail,
      rowIdsub,
      showDetails,
      showDetails2,
      setLoadingpreview,
      formik,
    },
    ref
  ) => {
    const [pdfDataUrl, setPdfDataUrl] = useState<string>('')
    
 
      const generatePDF = async () => {
        const doc: any = new jsPDF()

        doc.addFileToVFS('Poppins Regular 400-normal.ttf', Poppinsnormal)
        doc.addFileToVFS('Poppins Regular 700-bold.ttf', Poppinsbold)
        doc.addFileToVFS('Poppins Light 300-normal.ttf', Poppinslight)
        doc.addFileToVFS('Poppins Italic 400-normal.ttf', Poppinsitalic)
        doc.addFont('Poppins Regular 400-normal.ttf', 'Poppins Regular 400', 'normal')
        doc.addFont('Poppins Regular 700-bold.ttf', 'Poppins Bold 700', 'bold')
        doc.addFont('Poppins Light 300-normal.ttf', 'Poppins Light 300', 'light')
        doc.addFont('Poppins Italic 400-normal.ttf', 'Poppins Italic 400', 'italic')

        const pageWidth = doc.internal.pageSize.getWidth()

        // Function to add background before adding new page content

        const addBackground = () => {
          doc.addImage(
            backgroundImage,
            'PNG',
            0,
            0,
            doc.internal.pageSize.getWidth(),
            doc.internal.pageSize.getHeight(),
            undefined,
            'FAST'
          )
        }

        // Add background to the first page
        addBackground() // Ensure this is called before adding content

        //topimage

        const logoWidth = 25 // New width for the logo
        const logoHeight = 25 // New height for the logo

        // Calculate X position to center the logo

        const xPosition = (pageWidth - logoWidth) / 2 // Center horizontally

        // Adjusted call to addImage with new size and centered position
        doc.addImage(logoimage, 'PNG', xPosition, 5, logoWidth, logoHeight, undefined, 'FAST')

        //topimage

        const tableColumn: any = [
          {content: 'Name', styles:{cellWidth: 70}},
          {content: 'Qty', styles: {halign: 'right'}},
          {content: 'S.Price', styles: {halign: 'right'}},
          {content: 'Disc.', styles: {halign: 'right'}},
          {content: 'T.Price', styles: {halign: 'right'}},
        ]
        const tableRows: any[] = []

        const quotationData = (data: any) => {
          return data.map((item: any) => {
            // Sum up quantities from all subRows for each item
            const totalQuantity = item.subRows.reduce(
              (sum: any, subRow: any) => sum + subRow.quantity_added,
              0
            )

            // Extract and return the desired fields in the order specified
            return {
              item_name: item.item_name, // Name
              totalQuantity: totalQuantity, // Qty
              selling_price: item.selling_price, // S.Price
              discount_amount: item.discount_amount,
              total_price: item.total_price,
              subRows: item.subRows,
            }
          })
        }

        const processedData = quotationData(tabledata)

        processedData.forEach((item: any) => {
          const rowData = [
            {content: item.item_name, styles: {font: 'Poppins Bold 700', fontStyle: 'bold'}},
            {content: item.totalQuantity, styles: {halign: 'right'}},
            {
              content: `${addCommas(item.selling_price.toFixed(2))} ${' '} ${CURRENCY_APP}`,
              styles: {halign: 'right'},
            },
            {
              content: `${addCommas(item.discount_amount.toFixed(2))} ${' '} ${CURRENCY_APP}`,
              styles: {halign: 'right'},
            },
            {
              content: `${addCommas(item.total_price.toFixed(2))} ${' '} ${CURRENCY_APP}`,
              styles: {font: 'Poppins Bold 700', fontStyle: 'bold', halign: 'right'},
            },
          ]
          tableRows.push(rowData)

          if (showDetails) {
            item.subRows.forEach((subRow: any) => {
              const subRowData = [
                // Assuming you want to indent the subRow's item_name for visual distinction
                {
                  content: `  - ${subRow.item_name}`,
                  styles: {
                    font: 'Poppins Regular 400',
                    fontStyle: 'italic',
                    fontSize: 8,
                    fillColor: [211, 211, 211],
                  },
                },
                {
                  content: subRow.quantity_added,
                  styles: {fontSize: 8, fillColor: [211, 211, 211], halign: 'right'},
                },
                {
                  content: `${addCommas(subRow.unit_price.toFixed(2))} ${' '} ${CURRENCY_APP}`,
                  styles: {fontSize: 8, fillColor: [211, 211, 211], halign: 'right'},
                },
                {content: '', styles: {fontSize: 8, fillColor: [211, 211, 211], halign: 'right'}},
                {
                  content: `${addCommas(subRow.sub_total.toFixed(2))} ${' '} ${CURRENCY_APP}`,
                  styles: {fontSize: 8, fillColor: [211, 211, 211], halign: 'right'},
                },
              ]
              tableRows.push(subRowData)
            })
          }
        })
        const headerContent: any = [
          // First row for quotation details, "From" and "To" info will follow

          [
            {
              content: `**Quote Ref No:** ${formik.values.quotation_no} \n**Reference:** ${
                formik.values.client_ref
              }\n**Date:** ${dayjs(formik.values.quotation_date).format(
                'DD/MM/YYYY'
              )}\n**Expiry Date:** ${dayjs(formik.values.validity).format('DD/MM/YYYY')}`,
              colSpan: 2,
            },
          ],
          [
            {
              content: `**From:**\n**SIGNAL ENERGY SOLUTIONS LIMITED**\n**VAT No: P051663966T**\nP.O BOX 335 - 00600, Nairobi Kenya\nOff Falcon Road, Enterprise Road, Industrial Area, Nairobi Kenya`,
              styles: {cellWidth: 95},
            },
            {
              content: `**To:**\n**${formik.values.business_name}**\n**VAT No: ${formik.values.vat_no}**\n${formik.values.address}\n${formik.values.location}`,
              styles: {cellWidth: 95},
            },
          ],
        ]

        autoTable(doc, {
          startY: 32,
          head: [
            [
              {
                content: 'QUOTATION',
                colSpan: 4,
                styles: {font: 'Poppins Bold 700', fontStyle: 'bold'},
              },
            ],
          ],
          body: headerContent,
          theme: 'plain',
          pageBreak: 'avoid',
          margin: {top: 10, right: 5, bottom: 25, left: 5},
          headStyles: {
            fillColor: [45, 85, 159],
            textColor: [255, 255, 255],
            fontSize: 14,
            halign: 'center',
            valign: 'middle',
            cellPadding: 2,
            font: 'Poppins Bold 700',
            fontStyle: 'bold',
          },
          bodyStyles: {
            cellPadding: [5, 0, 0, 5],
            
          },
          willDrawCell: function (data: any) {
            if (data.section === 'body' && (data.column.index === 0 || data.column.index === 1)) {
              const lines = doc.splitTextToSize(data.cell.text, data.cell.width)
              let yPos = data.cell.y + 7 // Adjust starting y position as needed
              const customLineHeight = 4.2 // Custom line height for better control

              lines.forEach((line: any) => {
                let xPosition = data.cell.x // Default left-aligned position for the first column
                // For the second and third columns, calculate the right-aligned x position

                // Split the line into parts for separate styling
                const parts = line.split(/(\*\*.*?\*\*)/).filter((part: any) => part !== '') // Filter empty strings
                let newXPosition = xPosition

                parts.forEach((part: any) => {
                  if (part.startsWith('**') && part.endsWith('**')) {
                    // Remove the '**' and apply bold style
                    const boldText = part.substring(2, part.length - 2)
                    doc.setFont('Poppins Bold 700', 'bold')

                    doc.text(boldText.trim(), xPosition, yPos)
                    newXPosition += doc.getTextWidth(boldText) // Update position for the next part
                  } else {
                    // Use normal font for parts without bold styling
                    doc.setFont('Poppins Regular 400', 'normal')
                    doc.text(part, newXPosition, yPos)
                  
                    newXPosition += doc.getTextWidth(part) // Update position for the next part
                  }
                })

                yPos += customLineHeight // Increment yPos for the next line
              })

              data.cell.text = '' // Clear the cell text to prevent autoTable from drawing it automatically
            }
          },
        })

        autoTable(doc, {
          startY: 95,
          head: [tableColumn],
          body: tableRows,
          showFoot: 'lastPage',
          theme: 'plain',
          margin: {top: 10, right: 5, bottom: 29, left: 5},
          headStyles: {
            fillColor: [45, 85, 159],
            textColor: [255, 255, 255],
            fontSize: 10,
            cellPadding: 2,
            font: 'Poppins Bold 700',
            fontStyle: 'bold',
          },
          bodyStyles: {
            font: 'Poppins Regular 400',
            fontStyle: 'normal',
            textColor: [0, 0, 0],
            lineWidth: 0.1,
            lineColor: [211, 211, 211],
          },
          didDrawPage: (data: any) => {
            // Add background
            addBackground()

            const contactInfoRows = [
              // Splitting each contact info into its own cell for individual icon placement
              [
                {content: '', styles: {cellWidth: 16}},
                {
                  content: 'Off Falcon Road, Enterprise Road, Industrial Area, Nairobi Kenya',
                  styles: {
                    halign: 'center',
                    cellWidth: 'auto',
                    font: 'Poppins Regular 400',
                    fontStyle: 'normal',
                  },
                },
                {
                  content: 'Page ' + doc.internal.getNumberOfPages(),
                  styles: {
                    halign: 'right',
                    cellWidth: 16,
                    font: 'Poppins Regular 400',
                    fontStyle: 'normal',
                    fontSize: 8,
                  },
                },
              ],
            ]

            doc.autoTable({
              head: [],
              body: contactInfoRows,
              startY: doc.internal.pageSize.height - 28, // Adjust startY position according to your needs
              theme: 'plain',
              margin: {top: 0, right: 5, bottom: 0, left: 5}, // Adjust margin as needed
              bodyStyles: {valign: 'bottom'}, // Ensure content aligns to the bottom of the cell
              didDrawCell: (data: any) => {
                if (data.cell.section === 'body' && data.row.index === 0) {
                  // Check if it's the body section and the first row
                  let icon = null
                  switch (
                    data.column.index // Corrected to properly check the column index
                  ) {
                    case 1: // Phone column
                      icon = Locationicon // Assuming Phoneicon is defined somewhere
                      break
                  }
                  if (icon) {
                    const iconMargin = 22.2 // Adjust as needed
                    const iconSize = 5 // Adjust size as needed
                    doc.addImage(
                      icon,
                      iconMargin + data.cell.x,
                      data.cell.y + (data.cell.height - iconSize) / 2,
                      iconSize,
                      iconSize,
                      undefined,
                      'FAST'
                    )
                  }
                }
              },

              didDrawPage: (data: any) => {
                const contactInfoRows2 = [
                  // Splitting each contact info into its own cell for individual icon placement

                  [
                    {
                      content: '+254 714 579 838 | +254 728 453 335',
                      styles: {
                        fillColor: [99, 104, 110],
                        halign: 'center',
                        textColor: [255, 255, 255],
                        font: 'Poppins Regular 400',
                        fontStyle: 'normal',
                      },
                    },
                    {
                      content: 'info@signalafrica.com',
                      styles: {
                        fillColor: [99, 104, 110],
                        halign: 'center',
                        textColor: [255, 255, 255],
                        font: 'Poppins Regular 400',
                        fontStyle: 'normal',
                      },
                    },
                    {
                      content: 'www.signalafrica.com',
                      styles: {
                        fillColor: [99, 104, 110],
                        cellWidth: 'wrap',
                        halign: 'center',
                        textColor: [255, 255, 255],
                        font: 'Poppins Regular 400',
                        fontStyle: 'normal',
                      },
                    },
                  ],
                  [
                    {
                      content: '',
                      colSpan: 3,
                      styles: {fillColor: [45, 85, 159], cellWidth: 'wrap', halign: 'center'},
                    },
                  ],
                ]

                doc.autoTable({
                  head: [],
                  body: contactInfoRows2,
                  startY: doc.internal.pageSize.height - 20, // Adjust startY position according to your needs
                  theme: 'plain',
                  margin: {top: 0, right: 5, bottom: 0, left: 5}, // Adjust margin as needed
                  bodyStyles: {valign: 'bottom'}, // Ensure content aligns to the bottom of the cell
                  didDrawCell: (data: any) => {
                    if (data.cell.section === 'body' && data.row.index === 0) {
                      // Check if it's the body section and the first row
                      let icon = null
                      switch (
                        data.column.index // Corrected to properly check the column index
                      ) {
                        case 0: // Phone column
                          icon = Phoneicon // Assuming Phoneicon is defined somewhere
                          break
                        case 1: // Email column
                          icon = Emailicon // Assuming Emailicon is correctly defined
                          break
                        case 2: // Website column
                          icon = Websiteicon // Assuming Webicon is correctly defined
                          break
                      }
                      if (icon) {
                        const iconMargin = 2 // Adjust as needed
                        const iconSize = 5 // Adjust size as needed
                        doc.addImage(
                          icon,
                          data.column.index === 0
                            ? iconMargin + 4 + data.cell.x
                            : iconMargin + data.cell.x,
                          data.cell.y + (data.cell.height - iconSize) / 2,
                          iconSize,
                          iconSize,
                          undefined,
                          'FAST'
                        )
                      }
                    }
                  },

                  didDrawPage: (data: any) => {},
                })
              },
            })
          },
        })

        const endContent: any = [
          // First row for quotation details, "From" and "To" info will follow

          [
            {
              content: `**Bank Accounts Details:**\n**Bank:** Kenya Commercial Bank (KCB) Kenya\n**A/C Name: SIGNAL ENERGY SOLUTIONS LIMITED**\n**KES A/C Number:** 1222921251\n**USD A/C Number:** 1313789224\n**Branch:** Mashariki\n**Branch Code:** 01249\n**Swift code:** KCBLKENX\n`,
              styles: {cellWidth: 110},
            },
            {
              content: `**Total Discount:**\n**Exclusive Total:**\n**VAT(16%):**\n\n\n\n**Total:**`,
              styles: {cellWidth: 50, halign: 'right'},
            },
            {
              content: `${
                addCommas(
                  tabledata
                    .reduce((sum: any, item: any) => sum + item.discount_amount, 0)
                    .toFixed(2)
                ) +
                ' ' +
                CURRENCY_APP
              }\n${
                addCommas(
                  (
                    tabledata.reduce((sum: any, item: any) => sum + item.total_price, 0) -
                    tabledata.reduce((sum: any, item: any) => sum + item.discount_amount, 0)
                  ).toFixed(2)
                ) +
                ' ' +
                CURRENCY_APP
              }\n${
                addCommas(
                  (
                    (tabledata.reduce((sum: any, item: any) => sum + item.total_price, 0) -
                      tabledata.reduce((sum: any, item: any) => sum + item.discount_amount, 0)) *
                    0.16
                  ).toFixed(2)
                ) +
                ' ' +
                CURRENCY_APP
              }\n\n\n\n**${
                addCommas(
                  (
                    (tabledata.reduce((sum: any, item: any) => sum + item.total_price, 0) -
                      tabledata.reduce((sum: any, item: any) => sum + item.discount_amount, 0)) *
                    1.16
                  ).toFixed(2)
                ) +
                ' ' +
                CURRENCY_APP
              }**`,
              styles: {cellWidth: 40, halign: 'right'},
            },
          ],
          [],
          [
            {
              content: `**Service Description:**\n\n${formik.values.service_description}\n`,
              colSpan: 3,
              styles: {
                lineWidth: 0.1,
                lineColor: [211, 211, 211],
                cellWidth: 'auto',
                cellPadding: [3, 3, 5, 3],
              },
            },
          ],
          [],
          [
            {
              content: `**Terms and Conditions:**\n\n${formik.values.terms_and_conditions}\n`,
              colSpan: 3,
              styles: {
                lineWidth: 0.1,
                lineColor: [211, 211, 211],
                cellWidth: 'auto',
                cellPadding: [3, 3, 5, 3],
              },
            },
          ],
          [],
          [
            {
              content: `Thank you for your business.`,
              colSpan: 3,
              styles: {font: 'Poppins Regular 400', fontStyle: 'normal', fontSize: '8'},
            },
          ],
        ]

        const endContent2: any = [
          // First row for quotation details, "From" and "To" info will follow

          [
            {
              content: `**Bank Accounts Details:**\n**Bank:** Kenya Commercial Bank (KCB) Kenya\n**A/C Name: SIGNAL ENERGY SOLUTIONS LIMITED**\n**KES A/C Number:** 1222921251\n**USD A/C Number:** 1313789224\n**Branch:** Mashariki\n**Branch Code:** 01249\n**Swift code:** KCBLKENX\n`,
              styles: {cellWidth: 110},
            },
            {
              content: `**Total Discount:**\n**Exclusive Total:**\n**VAT(16%):**\n\n\n\n**Total:**`,
              styles: {cellWidth: 50, halign: 'right'},
            },
            {
              content: `${
                addCommas(
                  tabledata
                    .reduce((sum: any, item: any) => sum + item.discount_amount, 0)
                    .toFixed(2)
                ) +
                ' ' +
                CURRENCY_APP
              }\n${
                addCommas(
                  (
                    tabledata.reduce((sum: any, item: any) => sum + item.total_price, 0) -
                    tabledata.reduce((sum: any, item: any) => sum + item.discount_amount, 0)
                  ).toFixed(2)
                ) +
                ' ' +
                CURRENCY_APP
              }\n${
                addCommas(
                  (
                    (tabledata.reduce((sum: any, item: any) => sum + item.total_price, 0) -
                      tabledata.reduce((sum: any, item: any) => sum + item.discount_amount, 0)) *
                    0.16
                  ).toFixed(2)
                ) +
                ' ' +
                CURRENCY_APP
              }\n\n\n\n**${
                addCommas(
                  (
                    (tabledata.reduce((sum: any, item: any) => sum + item.total_price, 0) -
                      tabledata.reduce((sum: any, item: any) => sum + item.discount_amount, 0)) *
                    1.16
                  ).toFixed(2)
                ) +
                ' ' +
                CURRENCY_APP
              }**`,
              styles: {cellWidth: 40, halign: 'right'},
            },
          ],
          [
            {
              content: `Thank you for your business.`,
              colSpan: 3,
              styles: {font: 'Poppins Regular 400', fontStyle: 'normal', fontSize: '8'},
            },
          ],
        ]

        autoTable(doc, {
          startY: doc.lastAutoTable.finalY,
          head: [],
          body: showDetails2 ? endContent: endContent2,
          theme: 'plain',
          pageBreak: 'avoid',
          margin: {top: 10, right: 5, bottom: 25, left: 5},
          willDrawCell: function (data: any) {
            if (
              data.section === 'body' &&
              (data.column.index === 0 || data.column.index === 1 || data.column.index === 2)
            ) {
             
              const paddingLeft = 3
              const paddingRight = 3
        

              let lines = doc.splitTextToSize(data.cell.text, data.cell.width)
              if(data.row.index === 2 ||data.row.index === 4){

                lines = doc.splitTextToSize(data.cell.text, data.cell.width - paddingLeft - paddingRight);
              }
              let yPos = data.cell.y + 7 // Adjust starting y position as needed
              const customLineHeight = 4.2 // Custom line height for better control

              lines.forEach((line: any) => {
                let xPosition = data.cell.x // Default left-aligned position for the first column
                if(data.row.index === 2 ||data.row.index === 4){

                  xPosition = data.cell.x + paddingLeft; 
                }
                // For the second and third columns, calculate the right-aligned x position
                if (data.column.index === 1 || data.column.index === 2) {
                  const lineWidth = doc.getTextWidth(line)
                  xPosition = data.cell.x + data.cell.width - lineWidth
                }

                // Split the line into parts for separate styling
                const parts = line.split(/(\*\*.*?\*\*)/).filter((part: any) => part !== '') // Filter empty strings
                let newXPosition = xPosition

                parts.forEach((part: any) => {
                  if (part.startsWith('**') && part.endsWith('**')) {
                    // Remove the '**' and apply bold style
                    const boldText = part.substring(2, part.length - 2)
                    doc.setFont('Poppins Bold 700', 'bold')

                    if (data.column.index === 1 || data.column.index === 2) {
                      const lineWidth = doc.getTextWidth(boldText)
                      xPosition = data.cell.x + data.cell.width - lineWidth
                    }
                    doc.text(boldText.trim(), xPosition, yPos)
                    newXPosition += doc.getTextWidth(boldText) // Update position for the next part
                  } else {
                    // Use normal font for parts without bold styling
                    doc.setFont('Poppins Regular 400', 'normal')
                    let linesnew = doc.splitTextToSize(part, 190)
                    doc.text(linesnew, newXPosition, yPos)
                  
                    newXPosition += doc.getTextWidth(part) // Update position for the next part
                  
                  }
                })

                yPos += customLineHeight
                
              })
              
              data.cell.text = '' // Clear the cell text to prevent autoTable from drawing it automatically
            }

            
          }
          
        })

        const pdfBlob = doc.output('blob') // Generate the PDF as a Blob
        const blobUrl = URL.createObjectURL(pdfBlob) // Create a URL for the Blob

        setPdfDataUrl(blobUrl) // Set the Blob URL for preview
      }
      
     
      
      useImperativeHandle(ref, () => ({
        Quotationviewnow() {
          
          generatePDF()
        },
      }))
    const downloadPDF = () => {
      const downloadLink = document.createElement('a')
      downloadLink.href = pdfDataUrl
      downloadLink.download = 'quotation.pdf'
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    }

    return (
      <Modal show={show} onHide={handleClose} centered dialogClassName='modal-lg'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h3 className='fw-bolder'>Quotation Preview</h3>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              onClick={handleClose}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
            </div>
          </div>
          <div className='modal-body'>
            {pdfDataUrl ? (
              <iframe src={pdfDataUrl} title='PDF Preview' width='100%' height='500px'></iframe>
            ) : (
              'Generating preview...'
            )}
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-light-primary' onClick={handleClose}>
              Close
            </button>
            <button type='button' className='btn btn-primary' onClick={downloadPDF}>
              Download PDF
            </button>
          </div>
        </div>
      </Modal>
    )
  }
)
