import { useQuery, useMutation } from '@tanstack/react-query';
import { createExpense, deleteExpense, getExpenses, updateExpense } from '../../apis/financial_management/Loadallexpenses';


// Use Expenses
export const useExpenses = (
  start_limit: number,
  pagination_size: number,
  itemto_order: string,
  desorasc: string,
  search_term: string,
  user_role_id: number,
  user_designation: number
) => useQuery({
  queryKey: ['expenses', start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id, user_designation],
  queryFn: () => getExpenses(start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id, user_designation),
  staleTime: 5 * 60 * 1000, // 5 minutes
});

// Add Expense
export const useAddExpense = (expenseAdded: any) => useMutation({
  mutationFn: () => createExpense(expenseAdded)
});

// Delete Expense
export const useDeleteExpense = (expenseToDelete: number) => useMutation({
  mutationFn: () => deleteExpense(expenseToDelete)
});

// Update Expense
export const useUpdateExpense = (expenseToUpdate: any) => useMutation({
  mutationFn: () => updateExpense(expenseToUpdate)
});
