import {KTSVG} from '../../../../../_metronic/helpers'
import {FC, useEffect, useState, forwardRef, useImperativeHandle, useRef} from 'react'
import {Modal} from 'react-bootstrap'
import Select from 'react-select'

import {useAuth} from '../../../../../app/modules/auth'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import {useFormik, useFormikContext} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useUpdateBrand } from '../../../../contextProviders/inventory_management/Inventorybrandsprovider'


dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(localizedFormat)

type Props = {
  show: boolean
  handleClose: () => void
  ref: any
  inventorybrandsitemtoupdate: any
}
const CURRENCY_APP = process.env.REACT_APP_CURRENCY
const INFINITE_ROWS = process.env.REACT_APP_INFINITE_ROWS
export const UpdateInventoryBrands: FC<Props> = forwardRef((props, ref) => {
  

  const [inventorybranditemsadded, setInventorybranditemsadded] = useState<any>({})
 
  const [loading, setLoading] = useState(false)

  const initialValues = {
    brand_name: '',
    description: 'To be updated.',
    margin:'0.00'

  }

  const eventSchema = Yup.object().shape({
    brand_name: Yup.string().required('Category type is required'), // ensuring brand_name is provided and is a string
    description: Yup.string().required('Description is required'),
    margin: Yup.number()
    .required('Margin is required')
    .min(0, 'Margin must be at least 0')
    .max(100, 'Margin must be at most 100'),
  })
  

  const {mutateAsync: updateInventorybrandmutateAsync} = useUpdateBrand(inventorybranditemsadded)

  const formik: any = useFormik({
    initialValues: initialValues,

    validationSchema: eventSchema,

    onSubmit: async (values: any, {setStatus, setSubmitting, resetForm}) => {
      const updatePayload: any = {brand_id: props.inventorybrandsitemtoupdate.brand_id};
    let isChanged = false; // Flag to track if there are any changes

    Object.keys(values).forEach((key: any) => {
      // Check if the value has changed from the initial value
      if (values[key] !== props.inventorybrandsitemtoupdate[key]) {
        updatePayload[key] = values[key];
        isChanged = true; // Mark as changed
      }
    });

    // Proceed only if there are changes
    if (isChanged) {
     
      setInventorybranditemsadded(updatePayload);
      setLoading(true); // Set loading true on change

      // Add your update logic here
    } else {
    
      setStatus("No changes to update.");
      setSubmitting(false);
    }
    },
  })
  useImperativeHandle(ref, () => ({
    Resetmodalvalues() {
      formik.resetForm({})
    
    
      setInventorybranditemsadded({})
      setLoading(false)
    },
  }))
  
  useEffect(() => {
    if (Object.keys(inventorybranditemsadded).length > 0) { // Check if there are changes
      updateInventorybrandmutateAsync(inventorybranditemsadded, {
        onSuccess: () => {
          setLoading(false);
          formik.resetForm({});
         
          props.handleClose();
        },
        onError: (error: any) => {
          console.error('API Error: ', error);
          formik.setStatus("There is an error updating the Inventory Item.");
          setLoading(false);
        
          setInventorybranditemsadded({});
        },
      });
    }
  }, [inventorybranditemsadded]);

  useEffect(() => {
    // Check if inventorybrandsitemtoupdate is not null or undefined
    if (props.inventorybrandsitemtoupdate) {
     
      formik.setValues({
        brand_name: props.inventorybrandsitemtoupdate?.brand_name ?? '',
        description: props.inventorybrandsitemtoupdate?.description ?? '',
       margin:props.inventorybrandsitemtoupdate?.margin ?? ''
       
        
    })}
   }, [props.inventorybrandsitemtoupdate, formik.setValues]);

  return (
    <Modal
      className='modal fade'
      id='kt_modal_inventorybrand_form'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={props.show}
      centered
      dialogClassName='modal-lg'
      aria-hidden='true'
      onHide={props.handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h3 className='fw-bolder'>Update Inventory Brand</h3>

          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={props.handleClose}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>

        <div className='modal-body'>
          {formik.status && (
            <div className='row mb-4'>
              <div className='col-12 d-flex justify-content-center'>
                <span className='text-danger'>{formik.status}</span>
              </div>
            </div>
          )}
          <form
            id='kt_modal_update_inventorybrand_form'
            noValidate
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            onSubmit={formik.handleSubmit}
          >
            {/* Unit Retail Price Input Group */}
            <div className='row'>
              <div className='col-lg-6 fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Brand Name</label>
                {/* end::Label */}

                <input
                  type='text'
                  name='brand_name' // Ensure this matches your Formik initial values key
                  className='form-control form-control-lg form-control-solid text-uppercase'
                  onChange={formik.handleChange} // Hook up Formik's handleChange
                  onBlur={formik.handleBlur('brand_name')} // For touch feedback
                  value={formik.values.brand_name} // Control input with Formik's state
                />

                {/* Error Message for Item Name */}
                {formik.touched.brand_name && formik.errors.brand_name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.brand_name}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-lg-6 fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Margin</label>
                {/* end::Label */}

                <input
                  type='text'
                  name='margin' // Ensure this matches your Formik initial values key
                  className='form-control form-control-lg form-control-solid text-end'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    formik.handleChange(e)
                    let val: any = e.target.value.replace(/[^0-9.]/g, '') // Keep only numeric values and dot
                    if (val === '') {
                      val = '0.00' // Default to '0.00' if input is cleared
                    } else {
                      val = parseFloat(val) // Convert string to float
                      val = Math.max(0, Math.min(100, val)) // Clamp value between 0 and 100
                      val = val.toFixed(2) // Format to 2 decimal places
                    }
                    formik.setFieldValue('margin', val) // Update Formik's field value
                  }}
                  onBlur={formik.handleBlur('margin')} // For touch feedback
                  value={`${formik.values.margin}%`} // Control input with Formik's state
                />

                {/* Error Message for Item Name */}
                {formik.touched.margin && formik.errors.margin && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.margin}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row'>
              <div className='col-lg-12 fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Description</label>
                <textarea
                  className='form-control form-control-lg form-control-solid'
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  onBlur={formik.handleBlur} // Corrected onBlur handler
                  name='description'
                  rows={2}
                ></textarea>
                {formik.touched.description && formik.errors.description && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.description}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>

        <div className='modal-footer border-0 mt-n5'>
          <button type='button' className='btn btn-light-primary' onClick={props.handleClose}>
            Cancel
          </button>
          <button
            type='submit'
            form='kt_modal_update_inventorybrand_form'
            className='btn btn-primary'
            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
          >
            {!loading && <span className='indicator-label'>Update Inventory Brand</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
})
