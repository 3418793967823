import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

// Project Categories
export function getProjectCategories(start_limit: number, pagination_size: number, itemto_order: string, desorasc: string, search_term: string, user_role_id: number, user_designation: number) {
  return axios.get(`${API_URL}/project_categories_get`, {
    params: {
      start_limit,
      pagination_size,
      itemto_order,
      desorasc,
      search_term,
      user_role_id,
      user_designation,
    },
  });
}

export function createProjectCategory(categoryData: any) {
  return axios.post(`${API_URL}/project_categories_add`, categoryData);
}

export function updateProjectCategory(categoryData: any) {
  return axios.put(`${API_URL}/project_categories_update`, categoryData);
}

export function deleteProjectCategory(category_id: number) {
  return axios.delete(`${API_URL}/project_categories_delete`, { params: { category_id } });
}
