import { useQuery, useMutation } from '@tanstack/react-query';
import { getClients, createClients, deleteClients, updateClients } from '../../apis/cs_management/Loadallclients'; // Ensure correct path

// Use Clients
export const useClients = (
  start_limit: number,
  pagination_size: number,
  itemto_order: string,
  desorasc: string,
  search_term: string,
  user_role_id: number,
  user_designation: number
) => useQuery({
  queryKey: ['clients', start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id, user_designation],
  queryFn: () => getClients(start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id, user_designation),
  placeholderData: (previousData: any) => previousData,
  staleTime: 5 * 60 * 1000, // 5 minutes
});

// Add Client
export const useAddClient = (clientAdded: any) => useMutation({
  mutationFn: () => createClients(clientAdded)
});

// Delete Client
export const useDeleteClient = (clientToDelete: number) => useMutation({
  mutationFn: () => deleteClients(clientToDelete)
});

// Update Client
export const useUpdateClient = (clientToUpdate: any) => useMutation({
  mutationFn: () => updateClients(clientToUpdate)
});
