import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

// Project Management
export function getProject(start_limit: number, pagination_size: number, itemto_order: string, desorasc: string, search_term: string, user_role_id: number, user_designation: number, project_filter?: number) {
  return axios.get(`${API_URL}/project_management_get`, {
    params: {
      start_limit,
      pagination_size,
      itemto_order,
      desorasc,
      search_term,
      user_role_id,
      user_designation,
      project_filter
    },
  });
}

export function createProject(projectData: any) {
  return axios.post(`${API_URL}/project_management_add`, projectData);
}

export function updateProject(projectData: any) {
  return axios.put(`${API_URL}/project_management_update`, projectData);
}

export function deleteProject(id: number) {
  return axios.delete(`${API_URL}/project_management_delete`, { params: { id } });
}
