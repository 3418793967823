import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

// Quotations
export function getQuotations(start_limit: number, pagination_size: number, itemto_order: string, desorasc: string, search_term: string, user_role_id: number, user_designation: number) {
  return axios.get(`${API_URL}/quotations_get`, {
    params: {
      start_limit,
      pagination_size,
      itemto_order,
      desorasc,
      search_term,

      user_role_id,
      user_designation,
   
    },
  });
}

export function createQuotation(quotationData: any) {
  return axios.post(`${API_URL}/quotations_add`, quotationData);
}

export function updateQuotation(quotationData: any) {
  return axios.put(`${API_URL}/quotations_update`, quotationData);
}

export function deleteQuotation(id: number) {
  return axios.delete(`${API_URL}/quotations_delete`, { params: { id } });
}
