import { useQuery, useMutation } from '@tanstack/react-query';
import { createInvoice, deleteInvoice, getInvoices, updateInvoice } from '../../apis/financial_management/Loadallinvoices';


// Use Invoices
export const useInvoices = (
  start_limit: number,
  pagination_size: number,
  itemto_order: string,
  desorasc: string,
  search_term: string,
  user_role_id: number,
  user_designation: number
) => useQuery({
  queryKey: ['invoices', start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id, user_designation],
  queryFn: () => getInvoices(start_limit, pagination_size, itemto_order, desorasc, search_term,user_role_id, user_designation),
  staleTime: 5 * 60 * 1000, // 5 minutes
});

// Add Invoice
export const useAddInvoice = (invoiceAdded: any) => useMutation({
  mutationFn: () => createInvoice(invoiceAdded)
});

// Delete Invoice
export const useDeleteInvoice = (invoiceToDelete: number) => useMutation({
  mutationFn: () => deleteInvoice(invoiceToDelete)
});

// Update Invoice
export const useUpdateInvoice = (invoiceToUpdate: any) => useMutation({
  mutationFn: () => updateInvoice(invoiceToUpdate)
});
