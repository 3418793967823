import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const API_URL2 = process.env.REACT_APP_API_URL_SOCKET

export function getBrands(start_limit: number, pagination_size: number, itemto_order: string, desorasc: string, search_term: string, user_role_id: number, user_designation: number) {
  return axios.get(`${API_URL}/brands_get`, { params: {start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id, user_designation}});
}

export function createBrands(brandsadded: any) {
  return axios.post(`${API_URL}/brands_add`, brandsadded);
}

export function deleteBrands(brand_id: number) {
  return axios.delete(`${API_URL}/brands_delete`, {params: {brand_id}});
}

export function updateBrands(brandstoupdate: any) {
  return axios.put(`${API_URL}/brands_update`, brandstoupdate);
}