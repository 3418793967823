import React, {FC, useEffect, useState, useCallback, useRef} from 'react'

import axios from 'axios'
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  expandRows,
  flexRender,
  Column,
  Table,
  ExpandedState,
  getFilteredRowModel,
  getExpandedRowModel,
  ColumnDef,
  SortingState,
} from '@tanstack/react-table'

import CountUp from 'react-countup'


type Props = {

 

 
  tabledata2: any[]
 
  columndata2: any

 


  
 
}
const API_URL = process.env.REACT_APP_API_URL
const CustomTableStyle: React.CSSProperties = {
  borderCollapse: 'collapse',
  borderSpacing: 0,
 
};

const CustomTableRowStyle: React.CSSProperties = {
  border: 'none',
  margin: 0,
  padding: 0,
};

const CustomTableCellStyle: React.CSSProperties = {
  border: 'none',
  margin: 0,
  padding: '0.1rem 0.1rem',
};
const Mainsubtableprintquotations: React.FC<Props> = ({
  
  tabledata2,
  columndata2
 
}) => {
  const CURRENCY_APP = process.env.REACT_APP_CURRENCY
const INFINITE_ROWS = process.env.REACT_APP_INFINITE_ROWS



  const tableInstance2: any = useReactTable({
    data: tabledata2,
    columns: columndata2,

    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: true,
    manualPagination: true,
    manualSorting: true,
    manualExpanding: true, // Enable manual expansion
    
    // Additional configuration as needed...
  })

  return (
    <>
      
      
          {/* begin::Table container */}
          
             <div className='table-responsive'>
             <table className='table table-hover table-rounded border border-gray-300 gy-5 gs-5'>
               <thead>
                 {tableInstance2.getHeaderGroups().map((headerGroup: any) => (
                   <tr
                     key={headerGroup.id}
                     className='fw-bolder fs-7 text-gray-800 border-bottom-1 border-gray-300 text-uppercase'
                   >
                     {headerGroup.headers.map((header: any) => (
                       <th
                         key={header.id}
                        
                       >
                         {header.isPlaceholder
                           ? null
                           : flexRender(header.column.columnDef.header, header.getContext())}
     
                       </th>
                     ))}
                   </tr>
                 ))}
               </thead>
               <tbody>
                 {tableInstance2.getRowModel().rows.map((row: any) => (
                   // Use React.Fragment to wrap adjacent JSX elements
                   <React.Fragment key={row.id}>
                     <tr className='fw-bold'>
                       {row.getVisibleCells().map((cell: any) => (
                         <td key={cell.id}>
                           {flexRender(cell.column.columnDef.cell, cell.getContext())}
                         </td>
                       ))}
                     </tr>
                     
                   </React.Fragment>
                 ))}
               </tbody>
             </table>
           </div>
     
        
  
    </>
  )
}

export {Mainsubtableprintquotations}
