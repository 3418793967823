import { useQuery, useMutation } from '@tanstack/react-query';
import { createProjectCategory, deleteProjectCategory, getProjectCategories, updateProjectCategory } from '../../apis/project_management/Loadallprojectcategories';


// Use Project Categories
export const useProjectCategories = (
  start_limit: number,
  pagination_size: number,
  itemto_order: string,
  desorasc: string,
  search_term: string,
  user_role_id: number,
  user_designation: number
) => useQuery({
  queryKey: ['projectcategories', start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id, user_designation],
  queryFn: () => getProjectCategories(start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id, user_designation),
  staleTime: 5 * 60 * 1000, // 5 minutes
});

// Add Project Category
export const useAddProjectCategory = (projectCategoryAdded: any) => useMutation({
  mutationFn: () => createProjectCategory(projectCategoryAdded)
});

// Delete Project Category
export const useDeleteProjectCategory = (projectCategoryToDelete: number) => useMutation({
  mutationFn: () => deleteProjectCategory(projectCategoryToDelete)
});

// Update Project Category
export const useUpdateProjectCategory = (projectCategoryToUpdate: any) => useMutation({
  mutationFn: () => updateProjectCategory(projectCategoryToUpdate)
});
