import React, {
  FC,
  useEffect,
  useState,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isBetween from 'dayjs/plugin/isBetween'
import {useReactToPrint} from 'react-to-print'
import {Column} from 'react-table'
import { Mainsubtable4 } from '../tables/Mainsubtable4'
import { createColumnHelper } from '@tanstack/react-table'
import { Maintableprintquotations } from '../tables/printing/Maintableprintquotations'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(localizedFormat)
dayjs.extend(isBetween)

const truncate = require('truncate')
const addCommas = (num: any) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
const CURRENCY_APP = process.env.REACT_APP_CURRENCY
const SALES_TAX_MADE = process.env.REACT_APP_SALES_TAX

export const PrintingQuotation = forwardRef((props: any, ref: any) => {

  type Quotations = {
    id: string
    item_name: any
    cost: number
    total_margin: number
    selling_price: number
    discount: number
    total_price: number
  }

  type Addeditemshelp = {
    id: number
    rowId: string
    item_name: string
    quantity_added: number
    unit_price: number
    sub_total: number
    parentRowId: string
    unit_landing_cost: number
    total_margin: number
  }

  const columnHelper = useMemo(() => createColumnHelper<Quotations>(), [])

  const columnHelper2 = useMemo(() => createColumnHelper<Addeditemshelp>(), [])

  const columndata1 = [
   
    columnHelper.accessor('item_name', {
      header: 'Name',
      cell: ({row, column, table}: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-7'>
             {row.original.item_name}
            </span>
          </>
        )
      },
    }),
    columnHelper.accessor('cost', {
      header: () => <div className='text-end w-100'>L.Cost</div>,
      cell: ({row}: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-end'>
              {addCommas(Number(row.original.cost).toFixed(2))} {CURRENCY_APP}
            </span>

          </>
        )
      },
    }),

    columnHelper.accessor('selling_price', {
      header: () => <div className='text-end w-100'>s.price</div>,
      cell: ({row}: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6  text-end'>
              {addCommas(Number(row.original.selling_price).toFixed(2))} {CURRENCY_APP}
            </span>
         
          </>
        )
      },
    }),

    columnHelper.accessor('total_price', {
      header: () => <div className='text-end w-100'>s.total</div>,
      cell: ({row}: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-end'>
              {addCommas(Number(row.original.total_price).toFixed(2))} {CURRENCY_APP}
            </span>
            <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7 text-end'>
              {row.original.subRows.reduce(
                (acc: any, {quantity_added}: any) => acc + quantity_added,
                0
              ) === 1
                ? row.original.subRows.reduce(
                    (acc: any, {quantity_added}: any) => acc + quantity_added,
                    0
                  ) + ' item'
                : row.original.subRows.reduce(
                    (acc: any, {quantity_added}: any) => acc + quantity_added,
                    0
                  ) + ' items'}
            </span>
          </>
        )
      },
    }),

   
  ]
  const columndata2 = [
    columnHelper2.accessor('item_name', {
      header: 'Name',

      cell: ({row}: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-7 text-truncated'>
              {row.original.item_name}
            </span>
            <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7 text-truncated'>
              ID: {row.original.id}
            </span>
          </>
        )
      },
    }),
    columnHelper2.accessor('unit_landing_cost', {
      header: () => <div className='text-end w-100'>L.Cost</div>,
      cell: ({row}: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-7  text-end'>
              {addCommas(Number(row.original.unit_landing_cost).toFixed(2))} {CURRENCY_APP}
            </span>
           
          </>
        )
      },
    }),

    columnHelper2.accessor('unit_price', {
      header: () => <div className='text-end w-100'>S.PRICE</div>,
      cell: (info: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-7  text-end'>
              {addCommas(info.getValue().toFixed(2))} {CURRENCY_APP}
            </span>
          </>
        )
      },
    }),

    columnHelper2.accessor('sub_total', {
      header: () => <div className='text-end w-100'>s.total</div>,
      cell: (info: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-7  text-end'>
              {addCommas(info.getValue().toFixed(2))} {CURRENCY_APP}
            </span>
            <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7 text-end'>
              {info.row.original.quantity_added === 1
                ? info.row.original.quantity_added + ' item'
                : info.row.original.quantity_added + ' items'}
            </span>
          </>
        )
      },
    }),
  ]

   



  return (
    <>
    
    <style>
{`
  @media print {
    
    body, html, .printingClass, .container {
      width: 100%;
      height: auto;
     
    -webkit-print-color-adjust: exact !important;

    color-adjust: exact !important;
  
    }
  
    .page-break {
      display: block;
    }
  
    @page {
      margin: 5mm;
      size: auto; /* Adjust page size to auto to ensure full coverage */
    }
    .container {
      background-image: url(${toAbsoluteUrl('/media/logos/signallogo2.jpg')}) !important;
      background-size: cover !important; 
      background-position: center !important; 
      background-repeat: repeat !important; 
    }
  }

`}


</style>
      
      <div className='printingClass' >
      
        <div ref={ref} className='container'
         >
          <div className=''>
            <div className='row p-1 d-flex justify-content-center align-items-center mb-5'>
              <div className='text-center'>
                <img
                  alt='Logo'
                  className='h-100px logo'
                  src={toAbsoluteUrl('/media/logos/signallogo.svg')}
                />
              </div>
            </div>
            <div className='row bg-primary p-2 d-flex justify-content-center align-items-center mb-5'>
              <div className='fs-1 text-white text-uppercase fw-bolder text-center'>QUOTATION</div>
            </div>
            <div className='row'>
              <div className='col-6'>
                <div className='d-flex justify-content-start fw-bold'>
                  <span className='text-dark mt-1  fs-5'>
                    Reference: 250A MAIN LV PANEL WITH CHANGEOVER - LESS PFC
                  </span>
                </div>

                <div className='d-flex justify-content-start'>
                  <span className='text-dark mt-1 fs-5'>
                    Quote Ref No: <span className='fw-bold'>QUO0000178</span>
                  </span>
                </div>
              </div>
              <div className='col-6'>
                <div className='d-flex justify-content-start'>
                  <span className='text-dark mt-1  fs-5'>
                    <span className='fw-bold'>Date:</span> {dayjs().format('LL')}
                  </span>
                </div>
                <div className='d-flex justify-content-start'>
                  <span className='text-dark mt-1  fs-5'>
                    <span className='fw-bold'>Date Due:</span> {dayjs().format('LL')}
                  </span>
                </div>
              </div>
            </div>

            <div className='row mt-10'>
              <div className='col-6'>
                <div className='d-flex justify-content-start'>
                  <span className='text-dark mt-1 fw-bold fs-5'>From:</span>
                </div>
                <div className='d-flex justify-content-start fw-bold'>
                  <span className='text-dark mt-1  fs-5'>SIGNAL ENERGY SOLUTIONS LIMITED</span>
                </div>
                <div className='d-flex justify-content-start'>
                  <span className='text-dark mt-1 fs-5'>VAT No: P051663966T</span>
                </div>
                <div className='d-flex justify-content-start'>
                  <span className='text-dark mt-1 fs-5'>P.O BOX 335 - 00600, Nairobi Kenya.</span>
                </div>
                <div className='d-flex justify-content-start'>
                  <span className='text-dark mt-1 fs-5'>Off Falcon Road, Enterprise Road</span>
                </div>
                <div className='d-flex justify-content-start'>
                  <span className='text-dark mt-1  fs-5'>Industrial Area, Nairobi Kenya</span>
                </div>
              </div>
              <div className='col-6'>
                <div className='d-flex justify-content-start'>
                  <span className='text-dark mt-1 fw-bold fs-5'>To:</span>
                </div>
                <div className='d-flex justify-content-start fw-bold'>
                  <span className='text-dark mt-1  fs-5'>SIGNAL ENERGY SOLUTIONS LIMITED</span>
                </div>
                <div className='d-flex justify-content-start'>
                  <span className='text-dark mt-1 fs-5'>VAT No: P051663966T</span>
                </div>
                <div className='d-flex justify-content-start'>
                  <span className='text-dark mt-1 fs-5'>P.O BOX 335 - 00600, Nairobi Kenya.</span>
                </div>
                <div className='d-flex justify-content-start'>
                  <span className='text-dark mt-1 fs-5'>Off Falcon Road, Enterprise Road</span>
                </div>
                <div className='d-flex justify-content-start'>
                  <span className='text-dark mt-1  fs-5'>Industrial Area, Nairobi Kenya</span>
                </div>
              </div>
            </div>
          </div>
          <div className='mt-5'>
            
                    <Maintableprintquotations
                          tabledata={props.tabledata}
                        
                          columndata={ columndata1}
                          columndata2={columndata2}
                         
                        /> 
                      
                   
   
              
            
          </div>
          <div className='mt-10'>
            <div className='row'>
              <div className='col-6'>
                <div className='d-flex justify-content-start'>
                  <h5 className='text-dark'>Bank Accounts Details:</h5>
                </div>
                <div className='d-flex justify-content-start'>
                  <span className='text-dark mt-1 fw-bold fs-5'>Bank : Kenya Commercial Bank (KCB) Kenya</span>
                </div>
                <div className='d-flex justify-content-start'>
                  <span className='text-dark mt-1 fw-bold fs-5'>A/C Name: SIGNAL ENERGY SOLUTIONS LIMITED</span>
                </div>
                <div className='d-flex justify-content-start'>
                  <span className='text-dark mt-1 fw-bold fs-5'>KES A/C Number: 1222921251</span>
                </div>
                <div className='d-flex justify-content-start'>
                  <span className='text-dark mt-1 fw-bold fs-5'>USD A/C Number: 1313789224</span>
                </div>
                <div className='d-flex justify-content-start'>
                  <span className='text-dark mt-1 fw-bold fs-5'>Branch: Mashariki</span>
                </div>
                <div className='d-flex justify-content-start'>
                  <span className='text-dark mt-1 fw-bold fs-5'>Branch Code: 01249</span>
                </div>
                <div className='d-flex justify-content-start'>
                  <span className='text-dark mt-1 fw-bold fs-5'>Swift code: KCBLKENX</span>
                </div>
              </div>
              <div className='col-6 d-flex justify-content-end'>
              <div className='col-6'>
                </div>
              <div className='col-6'>
              <div className='d-flex justify-content-end'>
                  <div className='text-dark mt-1 fw-bold fs-4'><span className='fw-bolder'>Total</span> : <span className='fw-bolder text-end'>{addCommas(
                        Number(
                          props.tabledata.reduce((acc: any, {total_price}: any) => acc + total_price, 0)
                        ).toFixed(2)
                      )}{' '}
                      {CURRENCY_APP}</span></div>
                </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row mt-10'>
                <div className='col-6 d-flex justify-content-start'>
                  <span>Thank you for your business</span>
                </div>
                <div className='col-6 d-flex justify-content-end'>
                
                </div>
              </div>
        </div>
      </div>
    </>
  )
})
