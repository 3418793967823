import { useQuery, useMutation } from '@tanstack/react-query';
import { getBrands, createBrands, deleteBrands, updateBrands } from '../../apis/inventory_management/Loadallbrands'; // Adjust import path as necessary

export const useBrands = (
  start_limit: number,
  pagination_size: number,
  itemto_order: string,
  desorasc: string,
  search_term: string,
  user_role_id: number,
  user_designation: number
) => useQuery({
  queryKey: ['inventorybrands', start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id, user_designation],
  queryFn: () => getBrands(start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id, user_designation),
  placeholderData: (previousData: any) => previousData,
  staleTime: 5 * 60 * 1000,
});

export const useAddBrand = (brandAdded: any) => useMutation({
  mutationFn: () => createBrands(brandAdded)
});

export const useDeleteBrand = (brandToDelete: number) => useMutation({
  mutationFn: () => deleteBrands(brandToDelete)
});

export const useUpdateBrand = (brandToUpdate: any) => useMutation({
  mutationFn: () => updateBrands(brandToUpdate)
});
