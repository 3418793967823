import {KTSVG} from '../../../_metronic/helpers'
import {FC, useEffect, useState, forwardRef, useImperativeHandle, useRef} from 'react'
import {Modal} from 'react-bootstrap'
import Select from 'react-select'
import {

  useUpdateInventory,
} from '../../contextProviders/inventory_management/Inventoryprovider'
import {useAuth} from '../../../app/modules/auth'
import {QueryClient} from '@tanstack/react-query'
import {useSelectStyles} from '../../helpers/useSelectStyles'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import {useFormik, useFormikContext} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useBrands } from '../../contextProviders/inventory_management/Inventorybrandsprovider'
import { useCategories } from '../../contextProviders/inventory_management/Inventorycategoriesprovider'
import { usePackagings } from '../../contextProviders/inventory_management/Inventorypackagingsprovider'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(localizedFormat)

type Props = {
  show: boolean
  handleClose: () => void
  ref: any
  inventoryitemtoupdate: any
}
const CURRENCY_APP = process.env.REACT_APP_CURRENCY
const INFINITE_ROWS = process.env.REACT_APP_INFINITE_ROWS
export const UpdateInventory: FC<Props> = forwardRef((props, ref) => {
  const [selectedBrand, setSelectedBrand] = useState<any>(null)
  const [selectedCategory, setSelectedCategory] = useState<any>(null)
  const [selectedPackaging, setSelectedPackaging] = useState<any>(null)
  const [inventoryitemsadded, setInventoryitemsadded] = useState<any>([])
  const {currentUser, logout} = useAuth()
  const [loading, setLoading] = useState(false)

  const initialValues = {
    item_name: '',
    quantity: '0',
    packaging_id: '',
    category_id: '',
    brand_id: '',
    reorder_level: '0',
    unit_reserved_price: '0.00',
    unit_selling_price: '0.00',
    unit_landing_cost: '0.00',
    inventory_code: '',
    part_number: '',
   
  }

  const eventSchema = Yup.object().shape({
    item_name: Yup.string().required('Item name is required'),
    quantity: Yup.number()
      .integer()
      .min(0, 'Quantity must be at least 0')
      .required('Quantity is required'),
    packaging_id: Yup.number().integer().required('Packaging is required'),
    category_id: Yup.number().integer().required('Category is required'),
    brand_id: Yup.number().integer().required('Brand is required'),
    reorder_level: Yup.number()
      .integer()
      .min(0, 'Reorder level must be at least 0')
      .required('Reorder level is required'),
    unit_reserved_price: Yup.number()
      .min(0, 'Unit reserved price must be non-negative')
      .required('Unit reserved price is required'),
    unit_selling_price: Yup.number()
      .min(0, 'Unit selling price must be non-negative')
      .required('Unit selling price is required'),
    unit_landing_cost: Yup.number()
      .min(0, 'Unit landing cost must be non-negative')
      .required('Unit landing cost is required'),
    inventory_code: Yup.string().required('Inventory code is required'),
    part_number: Yup.string().required('Part number is required')
   
  })
  

  const {
    isLoading: inventorycategoryisloading,
    isSuccess: inventorycategoryissuccess,
    data: inventorycategorydata,
  } = useCategories(
   
    0,
    Number(INFINITE_ROWS),
    "category_name",
    "ASC",
      "%",
    2,
    3

  )
  const {
    isLoading: inventorypackagingisloading,
    isSuccess: inventorypackagingissuccess,
    data: inventorypackagingdata,
  } = usePackagings(
    0,
    Number(INFINITE_ROWS),
    "packaging_type",
    "ASC",
      "%",
    2,
    3
  )

  const {
    isLoading: inventorybrandisloading,
    isSuccess: inventorybrandissuccess,
    data: inventorybranddata,
  } = useBrands( 0,
    Number(INFINITE_ROWS),
    "brand_name",
    "ASC",
      "%",
    2,
    3)
  const brandOptions = inventorybrandissuccess && Array.isArray(inventorybranddata?.data.brands)
  ? inventorybranddata.data.brands.map((brand: any) => ({
      value: brand.brand_id,
      label: brand.brand_name,
    }))
  : [];

 const categoryOptions = inventorycategoryissuccess && Array.isArray(inventorycategorydata?.data.categories)
  ? inventorycategorydata.data.categories.map((categories: any) => ({
      value: categories.category_id,
      label: categories.category_name,
    }))
  : [];
 const packagingOptions = inventorypackagingissuccess && Array.isArray(inventorypackagingdata?.data.packagings)
  ? inventorypackagingdata.data.packagings.map((packagings: any) => ({
      value: packagings.packaging_id,
      label: packagings.packaging_type,
    }))
  : [];

  

  const {mutateAsync: updateInventorymutateAsync} = useUpdateInventory(inventoryitemsadded)


  const formik: any = useFormik({
    initialValues: initialValues,

    validationSchema: eventSchema,

    onSubmit: async (values: any, {setStatus, setSubmitting, resetForm}) => {
      const updatePayload: any = {id: props.inventoryitemtoupdate.id};
    let isChanged = false; // Flag to track if there are any changes

    Object.keys(values).forEach((key: any) => {
      // Check if the value has changed from the initial value
      if (values[key] !== props.inventoryitemtoupdate[key]) {
        updatePayload[key] = values[key];
        isChanged = true; // Mark as changed
      }
    });

    // Proceed only if there are changes
    if (isChanged) {
      setInventoryitemsadded(updatePayload);
      setLoading(true); // Set loading true on change

      // Add your update logic here
    } else {
      // Handle case where no changes are made
      setStatus("No changes to update.");
      setSubmitting(false);
    }
    },
  })


  
  useImperativeHandle(ref, () => ({
    Resetmodalvalues() {
      formik.resetForm({})
      setSelectedBrand(null)
      setSelectedCategory(null)
      setSelectedPackaging(null)
     
    },
  }))
  const handleBlur = (e: any) => {
    const { name, value } = e.target;
    let formattedValue = "0.00"; // Default value
    if (value) {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue)) { // Check if the parsed value is a valid number
        formattedValue = parsedValue.toFixed(2); // Format to two decimal places if valid
      }
    }
    formik.setFieldValue(name, formattedValue);
  };
  
  const handleBlur2 = (e: any) => {
    const { name, value } = e.target;
    let formattedValue = "0"; // Default value
    if (value) {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue)) { // Check if the parsed value is a valid number
        formattedValue = parsedValue.toFixed(0); // Format to two decimal places if valid
      }
    }
    formik.setFieldValue(name, formattedValue);
  };
  

  useEffect(() => {
    if (Object.keys(inventoryitemsadded).length > 0) { // Check if there are changes
      updateInventorymutateAsync(inventoryitemsadded, {
        onSuccess: () => {
          setLoading(false);
          formik.resetForm({});
          setSelectedBrand(null);
          setSelectedCategory(null);
          setSelectedPackaging(null);
          props.handleClose();
        },
        onError: (error) => {
          console.error('API Error: ', error);
          formik.setStatus("There is an error updating the Inventory Item.");
          setLoading(false);
        
          setInventoryitemsadded({});
        },
      });
    }
  }, [inventoryitemsadded]);


  useEffect(() => {
    // Check if inventoryitemtoupdate is not null or undefined
    if (props.inventoryitemtoupdate) {
      const initialBrand = brandOptions.find((option: any) => option.value === props.inventoryitemtoupdate.brand_id);
      setSelectedBrand(initialBrand);
  
      const initialCategory = categoryOptions.find((option: any)=> option.value === props.inventoryitemtoupdate.category_id);
      setSelectedCategory(initialCategory);
  
      const initialPackaging = packagingOptions.find((option: any)=> option.value === props.inventoryitemtoupdate.packaging_id);
      setSelectedPackaging(initialPackaging);
      // Use Formik's setValues method to update the form values
      formik.setValues({
        item_name: props.inventoryitemtoupdate?.item_name ?? '',
        quantity: props.inventoryitemtoupdate?.quantity ?? '0',
        packaging_id: props.inventoryitemtoupdate?.packaging_id ?? '',
        category_id: props.inventoryitemtoupdate?.category_id ?? '',
        brand_id: props.inventoryitemtoupdate?.brand_id ?? '',
        reorder_level: props.inventoryitemtoupdate?.reorder_level ?? '0',
        unit_reserved_price: props.inventoryitemtoupdate?.unit_reserved_price ?? '0.00',
        unit_selling_price: props.inventoryitemtoupdate?.unit_selling_price ?? '0.00',
        unit_landing_cost: props.inventoryitemtoupdate?.unit_landing_cost ?? '0.00',
        inventory_code: props.inventoryitemtoupdate?.inventory_code ?? '',
        part_number: props.inventoryitemtoupdate?.part_number ?? '',
      
      });
    }
  }, [props.inventoryitemtoupdate, formik.setValues]);

  return (
    <Modal
      className='modal fade'
      id='kt_modal_inventory_form'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={props.show}
      centered
      dialogClassName='modal-lg'
      aria-hidden='true'
      onHide={props.handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h3 className='fw-bolder'>Update Inventory
          
          <span className='h-10px border-gray-200 border-start mx-2'></span>

           <span className='text-muted fw-bold fs-6'>{props.inventoryitemtoupdate.id}</span>
          </h3>

          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={props.handleClose}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>

        <div className='modal-body'>
        {formik.status &&
            <div className="row mb-4">
              <div className="col-12 d-flex justify-content-center">
                <span className='text-danger'>{formik.status}</span>
              </div>
            </div>}
            <form
              id='kt_modal_add_inventory_form'
              noValidate
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              onSubmit={formik.handleSubmit}
            >
            {/* Unit Retail Price Input Group */}
            <div className='row'>
              <div className='col-lg-6 fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Item Name</label>
                {/* end::Label */}

                <input
                  type='text'
                  name='item_name' // Ensure this matches your Formik initial values key
                  className='form-control form-control-lg form-control-solid text-uppercase'
                  onChange={formik.handleChange} // Hook up Formik's handleChange
                  onBlur={formik.handleBlur('item_name')} // For touch feedback
                  value={formik.values.item_name} // Control input with Formik's state
                />

                {/* Error Message for Item Name */}
                {formik.touched.item_name && formik.errors.item_name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.item_name}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-lg-6 fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Quantity</label>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid fw-bold text-end'
                  onChange={formik.handleChange}
                  value={formik.values.quantity}
                  name='quantity'
                  onBlur={handleBlur2}
                />
                {formik.touched.quantity && formik.errors.quantity && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.quantity}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* Inventory Code and Part Number Input Group */}
            <div className='row'>
              <div className='col-lg-6 fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Reorder Level</label>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid fw-bold text-end'
                  onChange={formik.handleChange}
                  value={formik.values.reorder_level}
                  name='reorder_level'
                  onBlur={handleBlur2}
                />
                {formik.touched.reorder_level && formik.errors.reorder_level && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.reorder_level}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='col-lg-6 fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Part Number</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  onChange={formik.handleChange}
                  value={formik.values.part_number}
                  onBlur={formik.handleBlur('part_number')}
                  name='part_number'
                />
                {formik.touched.part_number && formik.errors.part_number && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.part_number}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row'>
              <div className='col-lg-6 fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Inventory Code</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  onChange={formik.handleChange}
                  value={formik.values.inventory_code}
                  onBlur={formik.handleBlur('inventory_code')}
                  name='inventory_code'
                />
                {formik.touched.inventory_code && formik.errors.inventory_code && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.inventory_code}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-lg-6 fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Select Brand</label>
                <Select
                  options={brandOptions}
                  value={selectedBrand}
                  onChange={(option) => {
                    setSelectedBrand(option); 
                    formik.setFieldValue("brand_id", option ? option.value : ''); 
                  }}
                  placeholder='Select Brand ...'
                  styles={useSelectStyles()}
                  isLoading={inventorybrandisloading}
                  isSearchable={true}
                  escapeClearsValue
                 backspaceRemovesValue
                  onBlur={formik.handleBlur("brand_id")}
                />
                 {formik.touched.brand_id && formik.errors.brand_id && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.brand_id}</span>
                        </div>
                      </div>
                    )}
              </div>
            </div>
            {/* begin::Input group */}
            <div className='row'>
              <div className='col-lg-6 fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Select Packaging</label>
                <Select
                  options={packagingOptions}
                  value={selectedPackaging}
                  onChange={(option) => {
                    setSelectedPackaging(option); 
                    formik.setFieldValue("packaging_id", option ? option.value : ''); 
                  }}
                  placeholder='Select Packaging ...'
                  styles={useSelectStyles()}
                  isLoading={inventorypackagingisloading}
                  isSearchable={true}
                  escapeClearsValue
                 backspaceRemovesValue
                  onBlur={formik.handleBlur("packaging_id")}
                />
                 {formik.touched.packaging_id && formik.errors.packaging_id && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.packaging_id}</span>
                        </div>
                      </div>
                    )}
              </div>

              <div className='col-lg-6 fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Select Category</label>
                {/* end::Label */}
                <Select
                  options={categoryOptions}
                  value={selectedCategory}
                  onChange={(option) => {
                    setSelectedCategory(option); 
                    formik.setFieldValue("category_id", option ? option.value : ''); 
                  }}
                  placeholder='Select Category ...'
                  styles={useSelectStyles()}
                  isSearchable={true}
                  isLoading={inventorycategoryisloading}
                  escapeClearsValue
                  backspaceRemovesValue
                  
                  onBlur={formik.handleBlur("category_id")}
                />
                 {formik.touched.category_id && formik.errors.category_id && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.category_id}</span>
                        </div>
                      </div>
                    )}
              </div>
            </div>

            {/* end::Input group */}

            {/* begin::Input group */}
            <div className='row'>
              {/* begin::Label */}

              <div className='col-lg-6 fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Unit Landing Cost ({CURRENCY_APP})</label>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid fw-bold text-end'
                  onChange={formik.handleChange}
                  onBlur={handleBlur}
                  value={formik.values.unit_landing_cost}
                  name='unit_landing_cost'
                 
                />
                {formik.touched.unit_landing_cost && formik.errors.unit_landing_cost && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.unit_landing_cost}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-lg-6 fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Unit Selling Price ({CURRENCY_APP})</label>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid fw-bold text-end'
                  onChange={formik.handleChange}
                  onBlur={handleBlur}
                  value={formik.values.unit_selling_price}
                  name='unit_selling_price'
                 
                />
                {formik.touched.unit_selling_price && formik.errors.unit_selling_price && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.unit_selling_price}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* end::Input group */}

           

            {/* Unit Selling Price and Unit Landing Cost Input Group */}
            <div className='row'>
              <div className='col-lg-6 fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Unit Reserved Price ({CURRENCY_APP})</label>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid fw-bold text-end'
                  onChange={formik.handleChange}
                  onBlur={handleBlur}
                  value={formik.values.unit_reserved_price}
                  name='unit_reserved_price'
               
                />
                {formik.touched.unit_reserved_price && formik.errors.unit_reserved_price && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.unit_reserved_price}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>

        <div className='modal-footer border-0 mt-n5'>
          <button type='button' className='btn btn-light-primary' onClick={props.handleClose}>
            Cancel
          </button>
          <button
            type='submit'
            form='kt_modal_add_inventory_form'
            className='btn btn-success'
            
            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
          >
            {!loading && <span className='indicator-label'>Update Inventory</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
})
