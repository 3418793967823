import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

// Invoices
export function getInvoices(start_limit: number, pagination_size: number, itemto_order: string, desorasc: string, search_term: string, user_role_id: number, user_designation: number, project_filter?: number) {
  return axios.get(`${API_URL}/invoices_get`, {
    params: {
      start_limit,
      pagination_size,
      itemto_order,
      desorasc,
      search_term,
      user_role_id,
      user_designation,
      project_filter
    },
  });
}

export function createInvoice(invoiceData: any) {
  return axios.post(`${API_URL}/invoices_add`, invoiceData);
}

export function updateInvoice(invoiceData: any) {
  return axios.put(`${API_URL}/invoices_update`, invoiceData);
}

export function deleteInvoice(id: number) {
  return axios.delete(`${API_URL}/invoices_delete`, { params: { id } });
}
