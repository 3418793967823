import {useEffect, useState, useRef, useLayoutEffect, useCallback} from 'react'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {KTCard} from '../../../../_metronic/helpers'
import {KTCardBody} from '../../../../_metronic/helpers'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Maintable} from '../../../tables/Maintable'
import {useMemo} from 'react'
import LoadingBar from 'react-top-loading-bar'
import {useWindowWidth} from '@react-hook/window-size/throttled'

import CountUp from 'react-countup'

import {createColumnHelper} from '@tanstack/react-table'
import {useAuth} from '../../../../app/modules/auth'
import {ItemsSummary} from '../../../topdashboard/ItemsSummary'
import io from 'socket.io-client'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import Select from 'react-select'
import {useQuotations} from '../../../contextProviders/sales_marketing/Quotationsprovider'
import {Maintablequotations} from '../../../tables/sales_marketing/Maintablequotations'
import {useClients} from '../../../contextProviders/cs_management/Clientsprovider'
import {isNaN, useFormik, useFormikContext} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'

import {InputActionMeta} from 'react-select'
import {useSelectStyles} from '../../../helpers/useSelectStyles'
import {Maintablecreatequotations} from '../../../tables/sales_marketing/Maintablecreatequotations'
import {useInventory} from '../../../contextProviders/inventory_management/Inventoryprovider'
import {AddInventory} from './modals/AddInventory'
import {useReactToPrint} from 'react-to-print'
import {PrintingQuotation} from '../../../printing/PrintingQuotation'
import {Quotationview} from './modals/Quotationview'

const CURRENCY_APP = process.env.REACT_APP_CURRENCY
const INFINITE_ROWS = process.env.REACT_APP_INFINITE_ROWS
const OPENING_TIME = process.env.REACT_APP_OPENING_TIME

// Hook
dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(localizedFormat)

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/home',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const addCommas = (num: any) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
const socket = io('signalenergysocket.smatonsolutions.com')
const generateId = () => Math.random().toString(36).substring(2, 11)
const quotationOptions = [
  {value: 'SERVICE QUOTATION', label: 'SERVICE QUOTATION'},
  {value: 'GOODS QUOTATION', label: 'GOODS QUOTATION'},
  {value: 'ASSEMBLY QUOTATION', label: 'ASSEMBLY QUOTATION'},
]
const CreateQuotation = () => {
  const refloadingbar: any = useRef(null)
  // const queryClient: any = useQueryClient()
  const myRefsearch: any = useRef()
  const myRef: any = useRef()
  const myRef2: any = useRef()
  const truncate = require('truncate')
  const [modalShowQuotation, setModalShowQuotation] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedClient, setSelectedClient] = useState<any>(null)
  const [modalshowdelete, setModalshowdelete] = useState(false)
  const [inventory, setInventory] = useState<any>([])
  const [modalAddInventory, setModalAddInventory] = useState(false)
  const [modalUpdateInventory, setModalUpdateInventory] = useState(false)
  const [selectedQuotationOption, setSelectedQuotationOption] = useState<any>(null)
  const [movetofirstpage, setMovetofirstpage] = useState<boolean>(false)
  const [submittingready, setSubmittingready] = useState<boolean>(false)
  const {currentUser, logout} = useAuth()
  const selectRef = useRef<any | null>(null)
  const [quotationDetailsadded, setQuotationDetailsadded] = useState<any>({})
  const [pageCount, setPageCount] = useState<number>(0)
  const [isTableVisible, setIsTableVisible] = useState<boolean>(false)
  const [tableDatanew, setTableDatanew] = useState<any[]>([])
  const [serversort, setServersort] = useState<any>([])
  const [item_name, setItem_name] = useState('')
  const [quantity, setUnits_in_stock] = useState<number>(0)
  const [newSetprice, setNewSetprice] = useState<number>(0)
  const [pagination_size, setPagination_size] = useState<number>(10)
  const [itemto_order, setItemto_order] = useState<string>('id')
  const [desorasc, setDesorasc] = useState<string>('DESC')
  const [search_term, setSearch_term] = useState<string>('%')
  const [start_limit, setStart_limit] = useState<number>(0)
  const [newRowNumber, setNewRowNumber] = useState<number>(1)
  const [itemName, setItemName] = useState<string>('Edit Item Name')
  const [rowIdsub, setRowIdsub] = useState<string>('')
  const [newSubRowDetail, setNewSubRowDetail] = useState<string>('')
  const [cost, setCost] = useState<number>(0)
  const [totalMargin, setTotalMargin] = useState<number>(0)
  const [sellingPrice, setSellingPrice] = useState<number>(0)
  const [discount, setDiscount] = useState<number>(0)
  const [discount_amount, setDiscount_amount] = useState<number>(0)
  const [totalPrice, setTotalPrice] = useState<number>(0)
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const [showDetails2, setShowDetails2] = useState<boolean>(false)
  const [loadingpreview, setLoadingpreview] = useState<boolean>(false)
  const [nextQuotationNumber, setNextQuotationNumber] = useState('')
  const onlyWidth = useWindowWidth()
  const componenttoprint = useRef<any | null>(null)
  //quotations management
  const initialValues = {
    quotation_no: '',
    client_id: '',
    quotation_date: dayjs().format('YYYY-MM-DD'),
    total_amount: '0.00',
    service_description:
      'Standard electrical installation for buildings, including wiring and fixture setup.',
    terms_and_conditions:
      'Payment due within 30 days of project completion. All installations come with a 1 year warranty for parts and labor.',
    validity: dayjs().format('YYYY-MM-DD'),
    status: 'Pending',
    quotation_type: 'ASSEMBLY QUOTATION',
    client_ref: 'Not Provided',
  }

  const eventSchema = Yup.object().shape({
    quotation_no: Yup.string().required('Quotation number is required'),
    client_id: Yup.number()
      .integer()
      .positive('Client ID must be positive')
      .required('Client ID is required'),
    quotation_date: Yup.date().required('Quotation date is required'),
    service_description: Yup.string().required('Service description is required'),
    terms_and_conditions: Yup.string().required('Terms and conditions are required'),
    validity: Yup.date().required('Validity date is required'),
    quotation_type: Yup.string().required('Quotation type is required'),
    client_ref: Yup.string(), // Optional, as 'Not Provided' can be a default value
  })

  const {
    isLoading: inventory_managementisloading,
    isSuccess: inventory_managementdsuccess,
    data: inventory_managementdata,
    status: inventory_managementstatus,
    refetch: inventory_managementrefetch,
    isRefetching: inventory_managementisRefetching,
  }: any = useInventory(
    start_limit,
    Number(INFINITE_ROWS),
    itemto_order,
    desorasc,
    search_term,
    Number(currentUser?.user_role_id),
    Number(currentUser?.user_designation_id)
  )

  const {
    isLoading: quotations_managementisloading,
    isSuccess: quotations_managementdsuccess,
    data: quotations_managementdata,
    status: quotations_managementstatus,
    refetch: quotations_managementrefetch,
    isRefetching: quotations_managementisRefetching,
  }: any = useQuotations(
    0,
    Number(INFINITE_ROWS),
    'id',
    'ASC',
    '%',
    Number(currentUser?.user_role_id),
    Number(currentUser?.user_designation_id)
  )
  //quotations management
  type Quotations = {
    id: string
    item_name: any
    cost: number
    total_margin: number
    selling_price: number
    discount: number
    total_price: number
    discount_amount: number
  }

  type Addeditemshelp = {
    id: number
    rowId: string
    item_name: string
    quantity_added: number
    unit_price: number
    sub_total: number
    parentRowId: string
    unit_landing_cost: number
    total_margin: number
  }

  const columnHelper = useMemo(() => createColumnHelper<Quotations>(), [])

  const columnHelper2 = useMemo(() => createColumnHelper<Addeditemshelp>(), [])

  const {
    isLoading: clientsisloading,
    isSuccess: clientsissuccess,
    data: clientsdata,
    status: clientsstatus,
    refetch: clientsrefetch,
    isRefetching: clientsisRefetching,
  }: any = useClients(
    start_limit,
    pagination_size,
    itemto_order,
    desorasc,
    search_term,
    Number(currentUser?.user_role_id),
    Number(currentUser?.user_designation_id)
  )
  const formik = useFormik({
    initialValues: initialValues,

    validationSchema: eventSchema,

    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      // Assuming a function like setQuotationDetails exists for handling quotation data
      setQuotationDetailsadded({
        quotation_no: values.quotation_no,
        client_id: values.client_id,
        quotation_date: values.quotation_date,
        total_amount: values.total_amount,
        service_description: values.service_description,
        terms_and_conditions: values.terms_and_conditions,
        validity: values.validity,
        status: values.status,
        quotation_type: values.quotation_type,
        client_ref: values.client_ref || 'Not Provided', // Defaulting to 'Not Provided' if empty
      })
      // Assuming setSubmittingready and setLoading are used for UI state management
      setSubmittingready(true) // Adjust the function name if necessary
      setLoading(true) // Adjust the function name if necessary
    },
  })

  const clientOptions =
    clientsissuccess && Array.isArray(clientsdata?.data.clients)
      ? clientsdata.data.clients.map((clients: any) => ({
          value: clients.id,
          label: clients.business_name,
          address: clients.address,
          location: clients.location,
          vat_no: clients.vat_no,
          email: clients.email,
        }))
      : []

  const handleDeleteRow = useCallback(
    (id: string): void => {
      if (tableDatanew.length === 1) {
        setNewRowNumber(1)
      }

      setTableDatanew(tableDatanew.filter((row) => row.id !== id))
    },
    [tableDatanew, setTableDatanew]
  )

  const handleAddItem = (): void => {
    // Show the table
    setIsTableVisible(true)

    const newRow: any = {
      id: generateId(),
      item_name: itemName + ' ' + newRowNumber,
      cost: cost,
      total_margin: totalMargin,
      selling_price: sellingPrice,
      discount: discount,
      total_price: totalPrice,
      discount_amount: discount_amount,
      subRows: [],
    }

    // Append the new row data to the existing table data
    setTableDatanew([...tableDatanew, newRow])
    setNewRowNumber(newRowNumber + 1)
    // Optionally, clear the input fields after adding the item
    // setItemName('');
    // setCost(0);
    // setTotalMargin(0);
    // setSellingPrice(0);
    // setDiscount(0);
    // setTotalPrice(0);
  }

  const handleCheckboxChange = (event: any) => {
    setShowDetails(event.target.checked)
  }
  const handleCheckboxChange2 = (event: any) => {
    setShowDetails2(event.target.checked)
  }
  const TableCell = ({row, column, table, initialValue}: any) => {
    const [value, setValue] = useState(initialValue)
    useEffect(() => {
      setValue(initialValue)
    }, [initialValue])
    const onBlur = () => {
      table.options.meta?.updateData(row.index, column.id, value)
    }
    return (
      <input
        className='form-control form-control-lg form-control-solid'
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
      />
    )
  }

  const TableCell2 = ({row, columnName, initialValue}: any) => {
    // Initialize value state with the initialValue prop
    const [value, setValue] = useState(initialValue)
    const [editedValue, setEditedValue] = useState(initialValue) // Keep track of the value being edited

    // Update value state whenever initialValue changes
    useEffect(() => {
      setValue(initialValue)
      setEditedValue(initialValue) // Reset editedValue when initialValue changes
    }, [initialValue])

    // Handles input changes, filtering non-numeric input, allowing decimals, and preventing values >= 100
    const handleChange = (e: any) => {
      // Allow only numeric values and decimal, slice to limit excessive decimal places
      let val = e.target.value.replace(/[^0-9.]/g, '').slice(0, 6)
      if (val === '') {
        val = '0'
      } else {
        const numericVal = parseFloat(val)
        // Prevent value from reaching or exceeding 100
        if (numericVal >= 100) {
          val = '99.999' // Adjust this to be as close to 100 as desired without exceeding it
        }
      }
      setValue(val)
      setEditedValue(val) // Update edited value
    }

    // Updates the parent component's data on input blur if the value has changed
    const handleBlur = () => {
      if (editedValue !== initialValue) {
        // Check if the value has actually changed
        setTableDatanew((prevData: any) =>
          prevData.map((item: any) =>
            item.id === row.original.id
              ? {
                  ...item,
                  [columnName]: parseFloat(editedValue),
                  total_price:
                    parseFloat(editedValue) === 0
                      ? item.total_price
                      : (item.cost / (1 - parseFloat(editedValue) / 100)) *
                        (1 - item.discount / 100),
                  discount_amount:
                    parseFloat(editedValue) === 0
                      ? item.discount_amount
                      : (item.cost / (1 - parseFloat(editedValue) / 100)) *
                        (1 - item.discount / 100) *
                        (item.discount / 100),
                }
              : item
          )
        )
      }
    }

    // Removes '%' for editing on focus and ensures correct display format
    const handleFocus = (e: any) => {
      // Remove '%' for editing and prevent starting with an invalid '100%' value
      const focusValue = value.replace('%', '')
      setValue(focusValue.startsWith('100') ? '99.999' : focusValue)
    }

    return (
      <input
        type='text'
        className='rounded border border-info fs-7 text-end w-60px fw-bold text-info'
        value={`${value}%`}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
    )
  }

  const TableCell3 = ({row, columnName, initialValue}: any) => {
    // Initialize value state with the initialValue prop
    const [value, setValue] = useState(initialValue)
    const [editedValue, setEditedValue] = useState(initialValue) // Track the value being edited

    // Update value state whenever initialValue changes
    useEffect(() => {
      setValue(initialValue)
      setEditedValue(initialValue) // Reset editedValue when initialValue changes
    }, [initialValue])

    // Handles input changes, filtering non-numeric input and ensuring the value is within range
    const handleChange = (e: any) => {
      // Keep only numeric values and allow decimal
      let val = e.target.value.replace(/[^0-9.]/g, '').slice(0, 6) // Slicing to limit length, preventing excessive decimal places
      if (val === '') {
        val = '0' // Default to '0' if input is cleared
      } else {
        const numericVal = parseFloat(val)
        // Prevent value from reaching or exceeding 100
        if (numericVal >= 100) {
          val = '99.999' // Adjust this value based on how close you want to get to 100 without exceeding it
        }
      }
      setValue(val) // Update state
      setEditedValue(val) // Update edited value
    }

    // Updates the parent component's data on input blur if the value has changed
    const handleBlur = () => {
      if (editedValue !== initialValue) {
        // Check if the value has actually changed
        setTableDatanew((prevData: any) =>
          prevData.map((item: any) =>
            item.id === row.original.id
              ? {
                  ...item,
                  [columnName]: parseFloat(editedValue),
                  total_price:
                    parseFloat(editedValue) === 0
                      ? item.total_price
                      : item.total_price * (1 - parseFloat(editedValue) / 100),
                  discount_amount:
                    parseFloat(editedValue) === 0
                      ? item.discount_amount
                      : item.total_price *
                        (1 - parseFloat(editedValue) / 100) *
                        (parseFloat(editedValue) / 100),
                }
              : item
          )
        )
      }
    }

    // Removes '%' for editing on focus and ensures correct display format
    const handleFocus = (e: any) => {
      // Remove '%' for editing and prevent starting with an invalid '100%' value
      const focusValue = value.replace('%', '')
      setValue(focusValue.startsWith('100') ? '99.999' : focusValue)
    }

    return (
      <input
        type='text'
        className='rounded border border-info fs-7 text-end w-60px fw-bold text-info'
        value={`${value}%`}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
    )
  }

  const TableCell4 = ({row, columnName, initialValue}: any) => {
    const [value, setValue] = useState(initialValue)
    const [editedValue, setEditedValue] = useState(initialValue) // Track the edited value

    useEffect(() => {
      setValue(initialValue) // Ensure value is always in sync with initialValue
      setEditedValue(initialValue) // Reset editedValue when initialValue changes
    }, [initialValue])

    const handleChange = (e: any) => {
      // Allow numeric values and decimal, slice to limit excessive decimal places
      let val = e.target.value.replace(/[^0-9.]/g, '').slice(0, 6)
      if (val === '') {
        val = '0'
      } else {
        const numericVal = parseFloat(val)
        // Prevent value from reaching or exceeding 100, allow close values like 99.999
        if (numericVal >= 100) {
          val = '99.999'
        }
      }
      setValue(val)
      setEditedValue(val) // Update edited value
    }

    const handleBlur = () => {
      // Update only if the edited value is different from the initial value
      if (editedValue !== initialValue) {
        setTableDatanew((prevData: any) =>
          prevData.map((item: any) => {
            if (item.id === row.original.parentRowId) {
              let cost = 0
              let selling_price = 0
              let total_price = 0

              // Update subRows and calculate cost, selling_price, and total_price
              const updatedSubRows = item.subRows.map((subRow: any) => {
                if (subRow.rowId === row.original.rowId) {
                  const updatedSubRow = {
                    ...subRow,
                    [columnName]: parseFloat(editedValue),
                    sub_total: subRow.unit_landing_cost / (1 - parseFloat(editedValue) / 100),
                  }

                  // Use the updated subRow for calculations
                  cost += updatedSubRow.unit_landing_cost
                  selling_price += updatedSubRow.unit_price
                  total_price += updatedSubRow.sub_total
                  return updatedSubRow
                } else {
                  // Use the original subRow for calculations
                  cost += subRow.unit_landing_cost
                  selling_price += subRow.unit_price
                  total_price += subRow.sub_total
                  return subRow
                }
              })

              // Return the item with updated subRows and recalculated totals
              return {
                ...item,
                subRows: updatedSubRows,
                cost: cost,
                selling_price: selling_price,
                total_price: isNaN(total_price * (1 - item.discount / 100))
                  ? total_price
                  : total_price * (1 - item.discount / 100),
                discount_amount: isNaN(total_price * (1 - item.discount / 100))
                  ? item.discount_amount
                  : total_price * (item.discount / 100),
              }
            }
            // Return unchanged item
            return item
          })
        )
      }
    }

    const handleFocus = () => {
      // Remove '%' for editing and prevent starting with an invalid '100%' value
      const focusValue = value.replace('%', '')
      setValue(focusValue.startsWith('100') ? '99.999' : focusValue)
    }

    return (
      <input
        type='text'
        className='rounded border border-primary fs-7 text-end w-60px fw-bold text-primary'
        value={`${value}%`}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
    )
  }

  const deleteSubRowFromRow = useCallback(
    (rowId: any, subRowId: any) => {
      setTableDatanew((currentTableData) =>
        currentTableData.map((row: any) => {
          if (row.id === rowId) {
            // Filter subRows first to avoid repeating the operation
            const filteredSubRows: any = row.subRows.filter(
              (subRow: any) => subRow.rowId !== subRowId
            )

            // Perform calculations with the filteredSubRows
            const cost = filteredSubRows.reduce(
              (acc: any, {unit_landing_cost}: any) => acc + unit_landing_cost,
              0
            )
            const selling_price = filteredSubRows.reduce(
              (acc: any, {unit_price}: any) => acc + unit_price,
              0
            )
            const total_price = filteredSubRows.reduce(
              (acc: any, {sub_total}: any) => acc + sub_total,
              0
            )

            // Return the updated row
            return {
              ...row,
              subRows: filteredSubRows,
              cost: cost,
              selling_price: selling_price,
              total_price: total_price,
            }
          }
          // Return unchanged row
          return row
        })
      )
    },
    [setTableDatanew]
  )

  const columndata1 = [
    columnHelper.display({
      id: 'expander', // Unique ID for the expander column
      header: () => null,

      meta: {
        width: 50,
      },
      cell: ({row}: any) =>
        // This creates the expander button or icon
        row.original &&
        row.original.subRows.length > 0 && (
          <div className='d-flex flex-column align-items-center'>
            <span onClick={row.getToggleExpandedHandler()} className='cursor-pointer mt-3'>
              {row.getIsExpanded() ? (
                <>
                  <span className='svg-icon svg-icon-primary'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      xmlnsXlink='http://www.w3.org/1999/xlink'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                    >
                      <g fill='none' fillRule='evenodd'>
                        <path d='M0 0L24 0 24 24 0 24z'></path>
                        <path
                          fill='#009ef7'
                          fillRule='nonzero'
                          d='M17.293 8.293a1 1 0 111.414 1.414l-6 6a1 1 0 01-1.383.03l-6-5.5a1 1 0 111.352-1.474l5.294 4.853 5.323-5.323z'
                        ></path>
                      </g>
                    </svg>
                  </span>
                </>
              ) : (
                <>
                  <span className='svg-icon svg-icon-primary'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      xmlnsXlink='http://www.w3.org/1999/xlink'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                    >
                      <g fill='none' fillRule='evenodd'>
                        <path d='M0 0L24 0 24 24 0 24z'></path>
                        <path
                          fill='#009ef7'
                          fillRule='nonzero'
                          d='M8.293 6.707a1 1 0 111.414-1.414l6 6a1 1 0 01.03 1.383l-5.5 6a1 1 0 11-1.474-1.352l4.853-5.294-5.323-5.323z'
                        ></path>
                      </g>
                    </svg>
                  </span>
                </>
              )}
            </span>
          </div>
        ),
      // Ensures the expander is not sortable or filterable
    }),
    columnHelper.accessor('item_name', {
      header: 'Name',
      cell: ({row, column, table}: any) => {
        return (
          <>
            <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7 text-end'>
              <TableCell
                row={row}
                column={column}
                table={table}
                columnName='item_name'
                initialValue={row.original.item_name}
              />
            </span>
          </>
        )
      },
    }),
    columnHelper.accessor('cost', {
      header: () => <div className='text-end w-100'>L.Cost</div>,
      cell: ({row}: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-end'>
              {addCommas(row.original.cost.toFixed(2))} {CURRENCY_APP}
            </span>

            <span className='d-flex justify-content-end align-items-center text-muted text-nowrap fw-bold d-block mb-1 fs-7'>
              <span className='badge border border-info text-info me-1 fw-bold'>M</span>{' '}
              <TableCell2
                row={row}
                columnName='total_margin'
                initialValue={(row.original.total_price === 0
                  ? 0
                  : ((row.original.total_price - row.original.cost) / row.original.total_price) *
                    100
                ).toFixed(2)}
              />
            </span>
          </>
        )
      },
    }),

    columnHelper.accessor('selling_price', {
      header: () => <div className='text-end w-100'>s.price</div>,
      cell: ({row}: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6  text-end'>
              {addCommas(row.original.selling_price.toFixed(2))} {CURRENCY_APP}
            </span>
            <span className='d-flex justify-content-end align-items-center text-muted text-nowrap fw-bold d-block mb-1 fs-7'>
              <span className='badge border border-info text-info me-1 fw-bold'>D</span>{' '}
              <TableCell3
                row={row}
                columnName='discount'
                initialValue={(row.original.discount === 0 ? 0 : row.original.discount).toFixed(2)}
              />
            </span>
          </>
        )
      },
    }),

    columnHelper.accessor('total_price', {
      header: () => <div className='text-end w-100'>s.total</div>,
      cell: ({row}: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-end'>
              {addCommas(row.original.total_price.toFixed(2))} {CURRENCY_APP}
            </span>
            <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7 text-end'>
              {row.original.subRows.reduce(
                (acc: any, {quantity_added}: any) => acc + quantity_added,
                0
              ) === 1
                ? row.original.subRows.reduce(
                    (acc: any, {quantity_added}: any) => acc + quantity_added,
                    0
                  ) + ' item'
                : row.original.subRows.reduce(
                    (acc: any, {quantity_added}: any) => acc + quantity_added,
                    0
                  ) + ' items'}
            </span>
          </>
        )
      },
    }),

    columnHelper.display({
      id: 'expander2', // Unique ID for the expander column
      header: () => null, // No header
      cell: (info) => (
        // This creates the expander button or icon
        <div className='d-flex justify-content-end flex-shrink-0'>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => {
              if (info.row.getIsExpanded() === false) {
                info.row.toggleExpanded()
              }
              showModalAddInventory(info.row.original.id, info.row.original.item_name)
            }}
          >
            <span className='svg-icon svg-icon-2'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <rect
                  opacity='0.5'
                  x='11.364'
                  y='20.364'
                  width='16'
                  height='2'
                  rx='1'
                  transform='rotate(-90 11.364 20.364)'
                  fill='#2d559f'
                />
                <rect x='4.36396' y='11.364' width='16' height='2' rx='1' fill='#2d559f' />
              </svg>
            </span>
          </a>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => {}}
          >
            <span className='svg-icon svg-icon-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  opacity='0.3'
                  d='M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z'
                  fill='black'
                ></path>
                <path
                  d='M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z'
                  fill='black'
                ></path>
              </svg>
            </span>
          </a>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            onClick={(index: any) => {
              handleDeleteRow(info.row.original.id)
            }}
          >
            {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
            <span className='svg-icon svg-icon-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={24}
                height={24}
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  d='M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z'
                  fill='#f1416c'
                />
                <path
                  opacity='0.5'
                  d='M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z'
                  fill='#f1416c'
                />
                <path
                  opacity='0.5'
                  d='M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z'
                  fill='#f1416c'
                />
              </svg>
            </span>
            {/*end::Svg Icon*/}
          </a>
        </div>
      ),
      // Ensures the expander is not sortable or filterable
    }),
  ]
  const columndata2 = [
    columnHelper2.accessor('item_name', {
      header: 'Name',

      cell: ({row}: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-7 text-truncated'>
              {row.original.item_name}
            </span>
            <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7 text-truncated'>
              ID: {row.original.id}
            </span>
          </>
        )
      },
    }),
    columnHelper2.accessor('unit_landing_cost', {
      header: () => <div className='text-end w-100'>L.Cost</div>,
      cell: ({row}: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-7  text-end'>
              {addCommas(row.original.unit_landing_cost.toFixed(2))} {CURRENCY_APP}
            </span>
            <span className='d-flex justify-content-end align-items-center text-muted text-nowrap fw-bold d-block mb-1 fs-7'>
              <span className='badge border border-primary text-primary me-1 fw-bold'>M</span>{' '}
              <TableCell4
                row={row}
                columnName='total_margin'
                initialValue={(row.original.sub_total === 0
                  ? 0
                  : ((row.original.sub_total - row.original.unit_landing_cost) /
                      row.original.sub_total) *
                    100
                ).toFixed(2)}
              />
            </span>
          </>
        )
      },
    }),

    columnHelper2.accessor('unit_price', {
      header: () => <div className='text-end w-100'>S.PRICE</div>,
      cell: (info) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-7  text-end'>
              {addCommas(info.getValue().toFixed(2))} {CURRENCY_APP}
            </span>
          </>
        )
      },
    }),

    columnHelper2.accessor('sub_total', {
      header: () => <div className='text-end w-100'>s.total</div>,
      cell: (info) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-7  text-end'>
              {addCommas(info.getValue().toFixed(2))} {CURRENCY_APP}
            </span>
            <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7 text-end'>
              {info.row.original.quantity_added === 1
                ? info.row.original.quantity_added + ' item'
                : info.row.original.quantity_added + ' items'}
            </span>
          </>
        )
      },
    }),

    columnHelper2.display({
      id: 'expander2', // Unique ID for the expander column
      header: () => null, // No header
      cell: (info: any) => (
        // This creates the expander button or icon
        <div className='d-flex justify-content-end flex-shrink-0'>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => {}}
          >
            <span className='svg-icon svg-icon-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  opacity='0.3'
                  d='M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z'
                  fill='black'
                ></path>
                <path
                  d='M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z'
                  fill='black'
                ></path>
              </svg>
            </span>
          </a>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            onClick={(index: any) => {
              deleteSubRowFromRow(info.row.original.parentRowId, info.row.original.rowId)
            }}
          >
            {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
            <span className='svg-icon svg-icon-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={24}
                height={24}
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  d='M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z'
                  fill='#f1416c'
                />
                <path
                  opacity='0.5'
                  d='M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z'
                  fill='#f1416c'
                />
                <path
                  opacity='0.5'
                  d='M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z'
                  fill='#f1416c'
                />
              </svg>
            </span>
            {/*end::Svg Icon*/}
          </a>
        </div>
      ),
      // Ensures the expander is not sortable or filterable
    }),
  ]
  const columndata3 = [
    columnHelper.display({
      id: 'expander', // Unique ID for the expander column
      header: () => null,
      meta: {
        width: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      },
      cell: ({row}: any) => (
        // This creates the expander button or icon
        <div className='d-flex flex-column align-items-center'>
          <span onClick={row.getToggleExpandedHandler()} className='cursor-pointer mt-3'>
            {row.getIsExpanded() ? (
              <>
                <span className='svg-icon svg-icon-primary'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    xmlnsXlink='http://www.w3.org/1999/xlink'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                  >
                    <g fill='none' fillRule='evenodd'>
                      <path d='M0 0L24 0 24 24 0 24z'></path>
                      <path
                        fill='#009ef7'
                        fillRule='nonzero'
                        d='M17.293 8.293a1 1 0 111.414 1.414l-6 6a1 1 0 01-1.383.03l-6-5.5a1 1 0 111.352-1.474l5.294 4.853 5.323-5.323z'
                      ></path>
                    </g>
                  </svg>
                </span>
              </>
            ) : (
              <>
                <span className='svg-icon svg-icon-primary'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    xmlnsXlink='http://www.w3.org/1999/xlink'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                  >
                    <g fill='none' fillRule='evenodd'>
                      <path d='M0 0L24 0 24 24 0 24z'></path>
                      <path
                        fill='#009ef7'
                        fillRule='nonzero'
                        d='M8.293 6.707a1 1 0 111.414-1.414l6 6a1 1 0 01.03 1.383l-5.5 6a1 1 0 11-1.474-1.352l4.853-5.294-5.323-5.323z'
                      ></path>
                    </g>
                  </svg>
                </span>
              </>
            )}
          </span>
        </div>
      ),
      // Ensures the expander is not sortable or filterable
    }),
    columnHelper.accessor('item_name', {
      header: 'Name',

      cell: (info) => {
        return (
          <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid fw-bold'
            />
          </span>
        )
      },
    }),
    columnHelper.accessor('cost', {
      header: () => <div className='text-end w-100'>cost</div>,
      cell: (info) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6  text-end'>
              {info.getValue()}
            </span>
            <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7  text-end'>
              Margin: {info.row.original.total_margin}
            </span>
          </>
        )
      },
    }),

    columnHelper.accessor('selling_price', {
      header: () => <div className='text-end w-100'>s.price</div>,
      cell: (info) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6  text-end'>
              {info.getValue()}
            </span>
            <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7  text-end'>
              Discount: {info.row.original.discount}
            </span>
          </>
        )
      },
    }),

    columnHelper.accessor('total_price', {
      header: () => <div className='text-end w-100'>T.Price</div>,
      cell: (info) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-end'>
              {info.getValue()}
            </span>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-7 text-end'>
              {info.row.getIsExpanded() ? 'true' : ' false'}
            </span>
          </>
        )
      },
    }),

    columnHelper.display({
      id: 'expander2', // Unique ID for the expander column
      header: () => null, // No header
      cell: (info) => (
        // This creates the expander button or icon
        <div className='d-flex justify-content-end flex-shrink-0'>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => {
              if (info.row.getIsExpanded() === false) {
                info.row.toggleExpanded()
              }
              showModalAddInventory(info.row.original.id, {detail: 'New Subrow Detail'})
            }}
          >
            <span className='svg-icon svg-icon-2'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <rect
                  opacity='0.5'
                  x='11.364'
                  y='20.364'
                  width='16'
                  height='2'
                  rx='1'
                  transform='rotate(-90 11.364 20.364)'
                  fill='#2d559f'
                />
                <rect x='4.36396' y='11.364' width='16' height='2' rx='1' fill='#2d559f' />
              </svg>
            </span>
          </a>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => {}}
          >
            <span className='svg-icon svg-icon-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  opacity='0.3'
                  d='M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z'
                  fill='black'
                ></path>
                <path
                  d='M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z'
                  fill='black'
                ></path>
              </svg>
            </span>
          </a>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            onClick={(index: any) => {
              handleDeleteRow(info.row.original.id)
            }}
          >
            {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
            <span className='svg-icon svg-icon-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={24}
                height={24}
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  d='M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z'
                  fill='#f1416c'
                />
                <path
                  opacity='0.5'
                  d='M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z'
                  fill='#f1416c'
                />
                <path
                  opacity='0.5'
                  d='M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z'
                  fill='#f1416c'
                />
              </svg>
            </span>
            {/*end::Svg Icon*/}
          </a>
        </div>
      ),
      // Ensures the expander is not sortable or filterable
    }),
  ]
  const columndata4 = [
    columnHelper2.accessor('item_name', {
      header: 'Name',

      cell: (info) => {
        return (
          <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid fw-bold'
            />
          </span>
        )
      },
    }),
    columnHelper2.accessor('quantity_added', {
      header: () => <div className='text-end w-100'>cost</div>,
      cell: (info) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6  text-end'>
              {info.getValue()}
            </span>
          </>
        )
      },
    }),

    columnHelper2.accessor('unit_price', {
      header: () => <div className='text-end w-100'>S.PRICE</div>,
      cell: (info) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6  text-end'>
              {info.getValue()}
            </span>
          </>
        )
      },
    }),

    columnHelper2.accessor('sub_total', {
      header: () => <div className='text-end w-100'>s.total</div>,
      cell: (info) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6  text-end'>
              {info.getValue()}
            </span>
          </>
        )
      },
    }),

    columnHelper2.display({
      id: 'expander2', // Unique ID for the expander column
      header: () => null, // No header
      cell: (info: any) => (
        // This creates the expander button or icon
        <div className='d-flex justify-content-end flex-shrink-0'>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => {}}
          >
            <span className='svg-icon svg-icon-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  opacity='0.3'
                  d='M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z'
                  fill='black'
                ></path>
                <path
                  d='M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z'
                  fill='black'
                ></path>
              </svg>
            </span>
          </a>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            onClick={(index: any) => {
              deleteSubRowFromRow(info.row.original.parentRowId, info.row.original.rowId)
            }}
          >
            {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
            <span className='svg-icon svg-icon-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={24}
                height={24}
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  d='M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z'
                  fill='#f1416c'
                />
                <path
                  opacity='0.5'
                  d='M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z'
                  fill='#f1416c'
                />
                <path
                  opacity='0.5'
                  d='M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z'
                  fill='#f1416c'
                />
              </svg>
            </span>
            {/*end::Svg Icon*/}
          </a>
        </div>
      ),
      // Ensures the expander is not sortable or filterable
    }),
  ]
  const columndata5 = [
    columnHelper.display({
      id: 'expander', // Unique ID for the expander column
      header: () => null,
      meta: {
        width: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      },
      cell: ({row}: any) => (
        // This creates the expander button or icon
        <div className='d-flex flex-column align-items-center'>
          <span onClick={row.getToggleExpandedHandler()} className='cursor-pointer mt-3'>
            {row.getIsExpanded() ? (
              <>
                <span className='svg-icon svg-icon-primary'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    xmlnsXlink='http://www.w3.org/1999/xlink'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                  >
                    <g fill='none' fillRule='evenodd'>
                      <path d='M0 0L24 0 24 24 0 24z'></path>
                      <path
                        fill='#009ef7'
                        fillRule='nonzero'
                        d='M17.293 8.293a1 1 0 111.414 1.414l-6 6a1 1 0 01-1.383.03l-6-5.5a1 1 0 111.352-1.474l5.294 4.853 5.323-5.323z'
                      ></path>
                    </g>
                  </svg>
                </span>
              </>
            ) : (
              <>
                <span className='svg-icon svg-icon-primary'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    xmlnsXlink='http://www.w3.org/1999/xlink'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                  >
                    <g fill='none' fillRule='evenodd'>
                      <path d='M0 0L24 0 24 24 0 24z'></path>
                      <path
                        fill='#009ef7'
                        fillRule='nonzero'
                        d='M8.293 6.707a1 1 0 111.414-1.414l6 6a1 1 0 01.03 1.383l-5.5 6a1 1 0 11-1.474-1.352l4.853-5.294-5.323-5.323z'
                      ></path>
                    </g>
                  </svg>
                </span>
              </>
            )}
          </span>
        </div>
      ),
      // Ensures the expander is not sortable or filterable
    }),
    columnHelper.accessor('item_name', {
      header: 'Name',

      cell: (info) => {
        return (
          <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid fw-bold'
            />
          </span>
        )
      },
    }),
    columnHelper.accessor('cost', {
      header: () => <div className='text-end w-100'>cost</div>,
      cell: (info) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6  text-end'>
              {info.getValue()}
            </span>
            <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7  text-end'>
              Margin: {info.row.original.total_margin}
            </span>
          </>
        )
      },
    }),

    columnHelper.accessor('selling_price', {
      header: () => <div className='text-end w-100'>s.price</div>,
      cell: (info) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6  text-end'>
              {info.getValue()}
            </span>
            <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7  text-end'>
              Discount: {info.row.original.discount}
            </span>
          </>
        )
      },
    }),

    columnHelper.accessor('total_price', {
      header: () => <div className='text-end w-100'>T.Price</div>,
      cell: (info) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-end'>
              {info.getValue()}
            </span>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-7 text-end'>
              {info.row.getIsExpanded() ? 'true' : ' false'}
            </span>
          </>
        )
      },
    }),

    columnHelper.display({
      id: 'expander2', // Unique ID for the expander column
      header: () => null, // No header
      cell: (info) => (
        // This creates the expander button or icon
        <div className='d-flex justify-content-end flex-shrink-0'>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => {
              if (info.row.getIsExpanded() === false) {
                info.row.toggleExpanded()
              }
              showModalAddInventory(info.row.original.id, {detail: 'New Subrow Detail'})
            }}
          >
            <span className='svg-icon svg-icon-2'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <rect
                  opacity='0.5'
                  x='11.364'
                  y='20.364'
                  width='16'
                  height='2'
                  rx='1'
                  transform='rotate(-90 11.364 20.364)'
                  fill='#2d559f'
                />
                <rect x='4.36396' y='11.364' width='16' height='2' rx='1' fill='#2d559f' />
              </svg>
            </span>
          </a>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => {}}
          >
            <span className='svg-icon svg-icon-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  opacity='0.3'
                  d='M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z'
                  fill='black'
                ></path>
                <path
                  d='M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z'
                  fill='black'
                ></path>
              </svg>
            </span>
          </a>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            onClick={(index: any) => {
              handleDeleteRow(info.row.original.id)
            }}
          >
            {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
            <span className='svg-icon svg-icon-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={24}
                height={24}
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  d='M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z'
                  fill='#f1416c'
                />
                <path
                  opacity='0.5'
                  d='M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z'
                  fill='#f1416c'
                />
                <path
                  opacity='0.5'
                  d='M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z'
                  fill='#f1416c'
                />
              </svg>
            </span>
            {/*end::Svg Icon*/}
          </a>
        </div>
      ),
      // Ensures the expander is not sortable or filterable
    }),
  ]
  const columndata6 = [
    columnHelper2.accessor('item_name', {
      header: 'Name',

      cell: (info) => {
        return (
          <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid fw-bold'
            />
          </span>
        )
      },
    }),
    columnHelper2.accessor('quantity_added', {
      header: () => <div className='text-end w-100'>cost</div>,
      cell: (info) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6  text-end'>
              {info.getValue()}
            </span>
          </>
        )
      },
    }),

    columnHelper2.accessor('unit_price', {
      header: () => <div className='text-end w-100'>S.PRICE</div>,
      cell: (info) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6  text-end'>
              {info.getValue()}
            </span>
          </>
        )
      },
    }),

    columnHelper2.accessor('sub_total', {
      header: () => <div className='text-end w-100'>s.total</div>,
      cell: (info) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6  text-end'>
              {info.getValue()}
            </span>
          </>
        )
      },
    }),

    columnHelper2.display({
      id: 'expander2', // Unique ID for the expander column
      header: () => null, // No header
      cell: (info: any) => (
        // This creates the expander button or icon
        <div className='d-flex justify-content-end flex-shrink-0'>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => {}}
          >
            <span className='svg-icon svg-icon-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  opacity='0.3'
                  d='M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z'
                  fill='black'
                ></path>
                <path
                  d='M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z'
                  fill='black'
                ></path>
              </svg>
            </span>
          </a>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            onClick={(index: any) => {
              deleteSubRowFromRow(info.row.original.parentRowId, info.row.original.rowId)
            }}
          >
            {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
            <span className='svg-icon svg-icon-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={24}
                height={24}
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  d='M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z'
                  fill='#f1416c'
                />
                <path
                  opacity='0.5'
                  d='M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z'
                  fill='#f1416c'
                />
                <path
                  opacity='0.5'
                  d='M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z'
                  fill='#f1416c'
                />
              </svg>
            </span>
            {/*end::Svg Icon*/}
          </a>
        </div>
      ),
      // Ensures the expander is not sortable or filterable
    }),
  ]
  useEffect(() => {
    if (inventory_managementdsuccess) {
      setInventory(
        inventory_managementdata?.data.inventory_management
          .filter((x: any) => x.quantity > 0)
          .sort((a: any, b: any) => (a.item_name > b.item_name ? 1 : -1))
          .filter((val: any) => val.quantity > 0)
          .map((val: any, index: any) => {
            return {
              value: val.item_name,
              label: val.item_name,
              quantity: val.quantity,
              unit_landing_cost: val.unit_landing_cost,
              category_id: val.category_id,
              packaging_id: val.packaging_id,
              id: val.id,
              unit_selling_price: val.unit_selling_price,
              product_division: 1,
              category_name: val.category_name,
              packaging_type: val.packaging_type,
              sales_tax: val.sales_tax,
            }
          })
      )
    }
  }, [inventory_managementdsuccess])

  const tabledata: any = useMemo(() => tableDatanew, [tableDatanew])

  const tabledatalength: number = useMemo(() => tableDatanew.length, [tableDatanew])

  const showModalAddInventory = (rowId: any, newSubRowDetail: any) => {
    setModalAddInventory(true)
    setRowIdsub(rowId)
    setNewSubRowDetail(newSubRowDetail)
    if (myRef && myRef.current) {
      myRef.current.Resetmodalvalues()
    }
  }

  useEffect(() => {
    const getNextQuotationNumber = () => {
      if (
        quotations_managementdsuccess &&
        quotations_managementdata?.data.quotations &&
        quotations_managementdata?.data.quotations.length > 0
      ) {
        // Assuming quotations_managementdata contains the array of quotations
        const lastQuotation =
          quotations_managementdata?.data.quotations[
            quotations_managementdata?.data.quotations.length - 1
          ].quotation_no
        const numPart = parseInt(lastQuotation.replace('QUO', ''), 10)
        const nextNumPart = numPart + 1
        return `QUO${nextNumPart.toString().padStart(6, '0')}`
      }
      return 'QUO000001' // Default if no quotations are present
    }

    setNextQuotationNumber(getNextQuotationNumber())
    formik.setFieldValue('quotation_no', getNextQuotationNumber())
  }, [quotations_managementdata?.data.quotations, quotations_managementdsuccess]) // Depend on quotations_managementdata

  return (
    <>
      <LoadingBar color='#009ef7' ref={refloadingbar} />
      <PageTitle breadcrumbs={usersBreadcrumbs}>Create Quotation</PageTitle>
      {/* begin::Row */}
      {/* begin::Row */}
      <>
        <div className='row g-5 gx-xxl-8'>
          <div className='col-xxl-12'>
            <KTCard>
              <KTCardBody className='py-4'>
                <div className='row mt-4 mb-5'>
                  <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                    <div className='mb-2'>
                      <h3 className='fw-bolder my-2'>Create Quotation</h3>
                    </div>
                  </div>
                  <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                    <div className='mb-2'>
                      {/* begin::Search */}

                      {/* end::Search */}
                    </div>
                  </div>
                </div>

                {formik.status && (
                  <div className='row mb-4'>
                    <div className='col-12 d-flex justify-content-center'>
                      <span className='text-danger'>{formik.status}</span>
                    </div>
                  </div>
                )}
                <form
                  id='kt_modal_add_inventorypackaging_form'
                  noValidate
                  className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                  onSubmit={formik.handleSubmit}
                >
                  {/* Unit Retail Price Input Group */}
                  <div className='row'>
                    <div className='col-lg-6 fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Quotation No.</label>

                      <input
                        type='text'
                        name='item_name'
                        className='form-control form-control-lg form-control-solid text-uppercase'
                        onChange={formik.handleChange} // Hook up Formik's handleChange
                        onBlur={formik.handleBlur('quotation_no')} // For touch feedback
                        value={formik.values.quotation_no} // Control input with Formik's state
                        disabled={true}
                      />

                      {formik.touched.quotation_no && formik.errors.quotation_no && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.quotation_no}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='col-lg-6 fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Quotation Reference.</label>

                      <input
                        type='text'
                        name='client_ref'
                        className='form-control form-control-lg form-control-solid text-uppercase'
                        onChange={formik.handleChange} // Hook up Formik's handleChange
                        onBlur={formik.handleBlur('client_ref')} // For touch feedback
                        value={formik.values.client_ref} // Control input with Formik's state
                      />

                      {formik.touched.client_ref && formik.errors.client_ref && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.client_ref}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-6 fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Select Quotation Type</label>

                      <Select
                        options={quotationOptions}
                        value={selectedQuotationOption}
                        onChange={(option: any) => {
                          setSelectedQuotationOption(option)
                          formik.setFieldValue('quotation_type', option ? option.value : '')
                        }}
                        placeholder='Select Quotation Type ...'
                        styles={useSelectStyles()}
                        isSearchable={true}
                        escapeClearsValue
                        backspaceRemovesValue
                        onBlur={formik.handleBlur('quotation_type')}
                      />

                      {formik.touched.quotation_type && formik.errors.quotation_type && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.quotation_type}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='col-lg-6 fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Select Client</label>
                      <Select
                        options={clientOptions}
                        value={selectedClient}
                        onChange={(option) => {
                          setSelectedClient(option)
                          formik.setFieldValue('client_id', option ? option.value : '')
                          formik.setFieldValue('business_name', option ? option.label : '')
                          formik.setFieldValue('address', option ? option.address : '')
                          formik.setFieldValue('location', option ? option.location : '')
                          formik.setFieldValue('email', option ? option.email : '')
                          formik.setFieldValue('vat_no', option ? option.vat_no : '')
                        }}
                        placeholder='Select Client ...'
                        styles={useSelectStyles()}
                        isLoading={clientsisloading}
                        isSearchable={true}
                        escapeClearsValue
                        backspaceRemovesValue
                        onBlur={formik.handleBlur('client_id')}
                      />
                      {formik.touched.client_id && formik.errors.client_id && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.client_id}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Inventory Code and Part Number Input Group */}
                  <div className='row'>
                    <div className='col-lg-6 fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Date</label>
                      <input
                        type='date'
                        id='datetimePicker'
                        className='form-control form-control-lg form-control-solid datetime-picker'
                        defaultValue={dayjs().format('YYYY/MM/DD')}
                        onChange={(e) => formik.setFieldValue('quotation_date', e.target.value)} // Added onChange handler
                        onBlur={formik.handleBlur('quotation_date')}
                        disabled={true}
                        value={formik.values.quotation_date}
                      />
                      {formik.touched.quotation_date && formik.errors.quotation_date && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.quotation_date}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='col-lg-6 fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Due Date</label>
                      <input
                        type='date'
                        id='datetimePicker'
                        className='form-control form-control-lg form-control-solid datetime-picker'
                        // Removed defaultValue to rely on value for controlled component behavior
                        value={formik.values.validity}
                        onChange={(e) => formik.setFieldValue('validity', e.target.value)} // Added onChange handler
                        onBlur={formik.handleBlur('validity')} // Ensure onBlur is correctly targeting 'validity'
                      />
                      {formik.touched.validity && formik.errors.validity && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.validity}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-12 fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Service Description</label>
                      <textarea
                        className='form-control form-control-lg form-control-solid'
                        onChange={formik.handleChange}
                        value={formik.values.service_description}
                        onBlur={formik.handleBlur} // Corrected onBlur handler
                        name='service_description'
                        rows={2}
                      ></textarea>
                      {formik.touched.service_description && formik.errors.service_description && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.service_description}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-lg-12 fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Terms and Conditions</label>
                      <textarea
                        className='form-control form-control-lg form-control-solid'
                        onChange={formik.handleChange}
                        value={formik.values.terms_and_conditions}
                        onBlur={formik.handleBlur} // Corrected onBlur handler
                        name='description'
                        rows={2}
                      ></textarea>
                      {formik.touched.terms_and_conditions && formik.errors.terms_and_conditions && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.terms_and_conditions}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* begin::Input group */}
                  <div className='fv-row mb-7'>
                    <div className='d-flex flex-column flex-grow-1'>
                      <div className='d-flex flex-wrap justify-content-end col-12'>
                        <button
                          type='button'
                          className='btn btn-light-danger me-2 mb-2'
                          disabled={tabledatalength === 0}
                          onClick={() => {
                            setIsTableVisible(false)
                            setTableDatanew([])
                            setNewRowNumber(1)
                          }}
                        >
                          Remove All Items
                        </button>

                        <button
                          type='button'
                          className='btn btn-primary mb-2'
                          onClick={() => {
                            handleAddItem()
                          }}
                        >
                          Add Item
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* end::Input group */}

                  {isTableVisible && tabledatalength > 0 && (
                    <div className='row'>
                      <div className='col-lg-12 fv-row mb-7'>
                        <Maintablecreatequotations
                          tabledata={tabledata}
                          tabledatalength={tabledatalength}
                          setIsTableVisible={setIsTableVisible}
                          setNewRowNumber={setNewRowNumber}
                          handleAddItem={handleAddItem}
                          setTableDatanew={setTableDatanew}
                          columndata={
                            onlyWidth <= 576
                              ? columndata3
                              : onlyWidth > 576 && onlyWidth <= 768
                              ? columndata5
                              : onlyWidth > 768 && onlyWidth <= 1200
                              ? columndata5
                              : columndata1
                          }
                          columndata2={
                            onlyWidth <= 576
                              ? columndata4
                              : onlyWidth > 576 && onlyWidth <= 768
                              ? columndata6
                              : columndata2
                          }
                        />
                      </div>
                    </div>
                  )}
                  {/* begin::Input group */}

                  <div className='separator separator-dashed mb-8 mt-4' />
                  <div className='row'>
                    <div className='col-lg-8 fv-row mb-7'></div>
                    <div className='col-lg-4 fv-row mb-7'>
                      {/*begin::Option*/}
                      <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-5'>
                        <span className='form-check-label ms-0 fw-bold fs-6 text-gray-700'>
                          Add Inventory Details
                        </span>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          defaultValue=''
                          checked={showDetails}
                          onChange={handleCheckboxChange} // Added onChange event handler here
                        />
                      </label>
                      <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-5'>
                        <span className='form-check-label ms-0 fw-bold fs-6 text-gray-700'>
                          Show Description & Terms
                        </span>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          defaultValue=''
                          checked={showDetails2}
                          onChange={handleCheckboxChange2} // Added onChange event handler here
                        />
                      </label>
                    </div>
                  </div>
                  <div className='separator separator-dashed mb-8 mt-4' />

                  <div className='fv-row mb-7'>
                    <div className='d-flex flex-column flex-grow-1'>
                      <div className='d-flex flex-wrap justify-content-end col-12'>
                        <button
                          type='button'
                          className='btn btn-light-info me-2 mb-2'
                          onClick={async () => {
                            setLoadingpreview(true) // Step 1: Start loading
                            setTimeout(async () => {
                              try {
                                if (myRef2 && myRef2.current) {
                                  myRef2.current.Quotationviewnow()
                                }
                              } catch (error) {
                                console.error('Error generating preview:', error)
                                // Handle any errors that might occur during preview generation
                              } finally {
                                setLoadingpreview(false) // Step 3: Stop loading, regardless of success or failure
                                // Step 4: Now that loading is done, show the preview.
                                // This might involve setting a state that triggers a modal to open or rendering the preview component.
                                setModalShowQuotation(true) // Assuming this opens the preview modal
                              }
                            }, 0)
                          }}
                          disabled={
                            tabledatalength === 0 ||
                            !tableDatanew.some((item) => item.subRows && item.subRows.length > 0) ||
                            loadingpreview || !formik.isValid || !formik.dirty
                          }
                        >
                          {!loadingpreview && (
                            <span className='indicator-label'>Preview Quotation</span>
                          )}
                          {loadingpreview && (
                            <span
                              className='indicator-progress text-info'
                              style={{display: 'block'}}
                            >
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>

                        <button
                          type='button'
                          className='btn btn-primary mb-2'
                          disabled={
                            tabledatalength === 0 || !formik.isValid || !formik.dirty ||
                            !tableDatanew.some((item) => item.subRows && item.subRows.length > 0) // Check if any item has subRows with length > 0
                          }
                          onClick={() => {}}
                        >
                          Save Quotation
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </KTCardBody>
            </KTCard>
          </div>
        </div>
      </>

      <AddInventory
        show={modalAddInventory}
        handleClose={function (): void {
          setModalAddInventory(false)
        }}
        ref={myRef}
        setTableDatanew={setTableDatanew}
        rowIdsub={rowIdsub}
        newSubRowDetail={newSubRowDetail}
      />

      <Quotationview
        show={modalShowQuotation}
        handleClose={function (): void {
          setModalShowQuotation(false)
        }}
        ref={myRef2}
        tabledata={tabledata}
        rowIdsub={rowIdsub}
        newSubRowDetail={newSubRowDetail}
        showDetails={showDetails}
        showDetails2= {showDetails2}
        setLoadingpreview={setLoadingpreview}
        formik={formik}
      />
      <PrintingQuotation ref={componenttoprint} tabledata={tabledata} />
    </>
  )
}

export default CreateQuotation
