import { useQuery, useMutation } from '@tanstack/react-query';
import { createInventoryRequest, deleteInventoryRequest, getInventoryRequests, updateInventoryRequest } from '../../apis/inventory_management/Loadallinventoryrequests';

// Use Inventory Requests
export const useInventoryRequests = (
  start_limit: number,
  pagination_size: number,
  itemto_order: string,
  desorasc: string,
  search_term: string,
  user_role_id: number,
  user_designation: number
) => useQuery({
  queryKey: ['inventoryrequests', start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id, user_designation],
  queryFn: () => getInventoryRequests(start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id, user_designation),
  staleTime: 5 * 60 * 1000, // 5 minutes
});

// Add Inventory Request
export const useAddInventoryRequest = (inventoryRequestAdded: any) => useMutation({
  mutationFn: () => createInventoryRequest(inventoryRequestAdded)
});

// Delete Inventory Request
export const useDeleteInventoryRequest = (inventoryRequestToDelete: number) => useMutation({
  mutationFn: () => deleteInventoryRequest(inventoryRequestToDelete)
});

// Update Inventory Request
export const useUpdateInventoryRequest = (inventoryRequestToUpdate: any) => useMutation({
  mutationFn: () => updateInventoryRequest(inventoryRequestToUpdate)
});
