import {FC, useEffect, useState, forwardRef, useImperativeHandle, useRef, useMemo} from 'react'
import {Modal} from 'react-bootstrap'
import Select from 'react-select'
import {useInventory} from '../../../../contextProviders/inventory_management/Inventoryprovider'
import {useAuth} from '../../../../../app/modules/auth'
import {useSelectStyles} from '../../../../helpers/useSelectStyles'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import {useFormik, useFormikContext} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useWindowWidth} from '@react-hook/window-size/throttled'
import {createColumnHelper} from '@tanstack/react-table'
import {Mainsubtable4} from '../../../../tables/Mainsubtable4'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(localizedFormat)

type Props = {
  show: boolean
  handleClose: () => void
  ref: any
  setTableDatanew: any
  newSubRowDetail: any
  rowIdsub: any
}
const CURRENCY_APP = process.env.REACT_APP_CURRENCY
const INFINITE_ROWS = process.env.REACT_APP_INFINITE_ROWS
const truncate = require('truncate')
function isNegative(num: any) {
  if (Math.sign(num) === -1) {
    return true
  }
  return false
}

export const AddInventory: FC<Props> = forwardRef(
  ({setTableDatanew, show, handleClose, newSubRowDetail, rowIdsub}, ref) => {
    const [submittingready, setSubmittingready] = useState<boolean>(false)
    const [inventorypackagingitemsadded, setInventoryitemsadded] = useState<any>({})
    const {currentUser, logout} = useAuth()
    const [loading, setLoading] = useState(false)
    const selectRef = useRef<any | null>(null)

    const [inventory, setInventory] = useState<any>([])

    const [item_name, setItem_name] = useState('')
    const [invoiceid, setInvoiceid] = useState<any>('')
    const [quantity, setUnits_in_stock] = useState<number>(0)
    const [unit_landing_cost, setUnit_landing_cost] = useState<number>(0)
    const [sales_tax, setSales_tax] = useState<number>(0)
    const [packaging_id, setProduct_unit_id] = useState<number>(0)
    const [id, setProduct_id] = useState<number>(0)
    const [unit_selling_price, setUnit_selling_price] = useState<number>(0)
    const [product_division, setProduct_division] = useState<number>(0)
    const onlyWidth = useWindowWidth()
    const [category_name, setCategory_name] = useState<string>('')
    const [packaging_type, setPackaging_type] = useState<string>('')
    const [category_id, setProduct_category_id] = useState('')
    const [currentItemsmade, setCurrentItemsmade] = useState<any>([])
    const [addeditemsLoaded, setAddeditemsLoaded] = useState(true)
    const [newSetquantity, setNewSetquantity] = useState<number>(0)
    const [newSetprice, setNewSetprice] = useState<number>(0)
    const [pagination_size, setPagination_size] = useState<number>(10)
    const [itemto_order, setItemto_order] = useState<string>('id')
    const [desorasc, setDesorasc] = useState<string>('DESC')
    const [search_term, setSearch_term] = useState<string>('%')
    const [start_limit, setStart_limit] = useState<number>(0)
    const addCommas = (num: any) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    const adddatatable = useMemo(() => currentItemsmade, [currentItemsmade])
    const [allsubtotalsmade, setAllsubtotalsmade] = useState<number>(0)
    const RoundNearstep = function (number: number) {
      return Number(
        Math.round(number * (1 / Number(product_division))) / (1 / Number(product_division))
      )
    }

    const {
      isLoading: inventory_managementisloading,
      isSuccess: inventory_managementdsuccess,
      data: inventory_managementdata,
      status: inventory_managementstatus,
      refetch: inventory_managementrefetch,
      isRefetching: inventory_managementisRefetching,
    }: any = useInventory(
      start_limit,
      Number(INFINITE_ROWS),
      itemto_order,
      desorasc,
      search_term,
      Number(currentUser?.user_role_id),
      Number(currentUser?.user_designation_id)
    )

    const addNewitemsTable = () => {
      const addeditemsintable = {
        quantity: Number(quantity - newSetquantity),
        sub_total: newSetprice,
        unit_price: Number(unit_selling_price) * Number(newSetquantity),
        quantity_added: Number(newSetquantity),
        item_name: item_name,
        id: id,
        rowId: `sub-${Math.random().toString(36).substring(2, 11)}`,
        user_id: currentUser?.user_id,
        sales_invoice_id: Number(invoiceid),
        packaging_id: packaging_id,
        sales_tax: Number(sales_tax),
        parentRowId: rowIdsub,
        unit_landing_cost: Number(unit_landing_cost) * Number(newSetquantity),
      }

      setInventory(
        inventory.map((x: any) => (x.id == id ? {...x, quantity: quantity - newSetquantity} : x))
      )

      setCurrentItemsmade([...currentItemsmade, addeditemsintable])
    }

    const removeNewitemsTable = (e: any) => {
      setNewSetquantity(0)
      setCurrentItemsmade([])
      setInventory(
        inventory_managementdata?.data.inventory_management
          .filter((x: any) => x.quantity > 0)
          .sort((a: any, b: any) => (a.item_name > b.item_name ? 1 : -1))
          .filter((val: any) => val.quantity > 0)
          .map((val: any, index: any) => {
            return {
              value: val.item_name,
              label: val.item_name,
              quantity: val.quantity,
              unit_landing_cost: val.unit_landing_cost,
              category_id: val.category_id,
              packaging_id: val.packaging_id,
              id: val.id,
              unit_selling_price: val.unit_selling_price,
              product_division: 1,
              category_name: val.category_name,
              packaging_type: val.packaging_type,
            }
          })
      )
    }
    const handlequantitychange = (e: any) => {
      setNewSetquantity(RoundNearstep(Number(e.target.value)))
    }

    const handlepricechange = (e: any) => {
      setNewSetprice(e.target.value)
    }
    const handleInventoryChange = (e: any) => {
      if (e != null) {
        setNewSetquantity(0)
        setNewSetprice(0)
        setUnits_in_stock(e.quantity)
        setUnit_landing_cost(e.unit_landing_cost)
        setProduct_category_id(e.category_id)
        setProduct_unit_id(e.packaging_id)
        setProduct_id(e.id)
        setUnit_selling_price(e.unit_selling_price)
        setItem_name(e.label)
        setProduct_division(e.product_division)
        setCategory_name(e.category_name)
        setPackaging_type(e.packaging_type)
        setSales_tax(e.sales_tax * e.unit_selling_price)
      }
    }

    const handleRemoveItem = (e: number) => {
      setInventory((prevProducts: any) => {
        return prevProducts.map((x: any) => {
          if (x.id === currentItemsmade[e].id) {
            return {
              ...x,
              quantity: x.quantity + currentItemsmade[e].quantity_added,
            }
          }
          return x
        })
      })

      setCurrentItemsmade((prevCurrentItemsmade: any) => {
        return prevCurrentItemsmade.filter((value: any, i: number) => i !== e)
      })
    }

    useImperativeHandle(ref, () => ({
      Resetmodalvalues() {
        setNewSetquantity(0)
        setNewSetprice(0)
        setUnits_in_stock(0)
        setUnit_landing_cost(0)
        setProduct_category_id('')
        setProduct_unit_id(0)
        setProduct_id(0)
        setUnit_selling_price(0)
        setItem_name('')
        setProduct_division(1)
        setCategory_name('')
        setPackaging_type('')
        setSales_tax(0)
        setSubmittingready(false)
        setInventoryitemsadded({})
        setCurrentItemsmade([])
        setLoading(false)
      }
    }))

    type addeditemshelp = {
      id: number
      item_name: string
      quantity_added: number
      unit_price: number
      sub_total: number
    }

    const columnHelper = createColumnHelper<addeditemshelp>()
    const columnaddedtitems = [
      columnHelper.accessor('id', {
        header: 'id',
        cell: (info) => {
          return (
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
              {info.getValue()}
            </span>
          )
        },
      }),

      columnHelper.accessor('item_name', {
        header: 'Name',
        cell: (info) => {
          return (
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
              {info.getValue()}
            </span>
          )
        },
      }),
      columnHelper.accessor('quantity_added', {
        header: () => <div className='text-end w-100'>Quantity</div>,
        cell: (info: any) => {
          return (
            <>
              <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-truncate text-end'>
                {info.getValue()}
              </span>
            </>
          )
        },
      }),
      columnHelper.accessor('unit_price', {
        header: () => <div className='text-end w-100'>Price</div>,
        cell: (info) => {
          return (
            <>
              <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-end'>
                {addCommas(info.getValue())} {CURRENCY_APP}
              </span>
            </>
          )
        },
      }),

      columnHelper.accessor('sub_total', {
        header: () => <div className='text-end w-100'>Total</div>,
        cell: (info) => {
          return (
            <>
              <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-end'>
                {addCommas(info.getValue())} {CURRENCY_APP}
              </span>
            </>
          )
        },
      }),

      columnHelper.display({
        id: 'expander2', // Unique ID for the expander column
        header: () => null, // No header
        cell: ({row}: any) => {
          return (
            <div className='d-flex justify-content-end flex-shrink-0'>
              <a
                href='#'
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm border'
                onClick={() => handleRemoveItem(row.index)}
              >
                {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
                <span className='svg-icon svg-icon-3'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <path
                      d='M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z'
                      fill='#f1416c'
                    />
                    <path
                      opacity='0.5'
                      d='M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z'
                      fill='#f1416c'
                    />
                    <path
                      opacity='0.5'
                      d='M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z'
                      fill='#f1416c'
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </a>
            </div>
          )
        },
      }),
    ]
    const columnaddedtitems2 = [
      columnHelper.accessor('item_name', {
        header: 'Name',
        cell: (info) => {
          return (
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>
              {info.getValue()}
            </span>
          )
        },
      }),
      columnHelper.accessor('quantity_added', {
        header: () => <div className='text-end w-100'>Quantity</div>,
        cell: (info: any) => {
          return (
            <>
              <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-truncate text-end'>
                {info.getValue()}
              </span>
            </>
          )
        },
      }),

      columnHelper.accessor('sub_total', {
        header: () => <div className='text-end w-100'>Total</div>,
        cell: (info) => {
          return (
            <>
              <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-end'>
                {addCommas(info.getValue())} {CURRENCY_APP}
              </span>
            </>
          )
        },
      }),

      columnHelper.display({
        id: 'expander2', // Unique ID for the expander column
        header: () => null, // No header
        cell: ({row}: any) => {
          return (
            <div className='d-flex justify-content-end flex-shrink-0'>
              <a
                href='#'
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm border'
                onClick={() => handleRemoveItem(row.index)}
              >
                {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
                <span className='svg-icon svg-icon-3'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <path
                      d='M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z'
                      fill='#f1416c'
                    />
                    <path
                      opacity='0.5'
                      d='M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z'
                      fill='#f1416c'
                    />
                    <path
                      opacity='0.5'
                      d='M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z'
                      fill='#f1416c'
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </a>
            </div>
          )
        },
      }),
    ]

    useEffect(() => {
      if (inventory_managementdsuccess) {
        setInventory(
          inventory_managementdata?.data.inventory_management
            .filter((x: any) => x.quantity > 0)
            .sort((a: any, b: any) => (a.item_name > b.item_name ? 1 : -1))
            .filter((val: any) => val.quantity > 0)
            .map((val: any, index: any) => {
              return {
                value: val.item_name,
                label: val.item_name,
                quantity: val.quantity,
                unit_landing_cost: val.unit_landing_cost,
                category_id: val.category_id,
                packaging_id: val.packaging_id,
                id: val.id,
                unit_selling_price: val.unit_selling_price,
                product_division: 1,
                category_name: val.category_name,
                packaging_type: val.packaging_type,
                sales_tax: val.sales_tax,
              }
            })
        )
      }
    }, [inventory_managementdsuccess])

    useEffect(() => {
      if (newSetquantity >= 0) {
        setNewSetprice(
          Number(unit_selling_price) * Number(newSetquantity) + sales_tax * Number(newSetquantity)
        )
      }
      if (newSetquantity > quantity) {
        setNewSetquantity(quantity)
        setNewSetprice(Number(unit_selling_price) * Number(quantity) + sales_tax * Number(quantity))
      }
    }, [newSetquantity])

    useEffect(() => {
      if (currentItemsmade.length > 0) {
        setAddeditemsLoaded(false)
      } else {
        setAddeditemsLoaded(true)
      }
      let allsubtotals: number = 0
      currentItemsmade.forEach((a: any) => (allsubtotals += parseFloat(a.sub_total)))

      setAllsubtotalsmade(allsubtotals)
    }, [currentItemsmade])

    return (
      <Modal
        className='modal fade'
        id='kt_modal_inventorypackaging_form'
        data-backdrop='static'
        tabIndex={-1}
        role='dialog'
        show={show}
        centered
        dialogClassName='modal-lg'
        aria-hidden='true'
        onHide={handleClose}
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h3 className='fw-bolder'>Add Inventory</h3>

            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              onClick={handleClose}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
            </div>
          </div>

          <div className='modal-body'>
            <form
              id='kt_modal_add_inventorypackaging_form'
              noValidate
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            >
              <div className='row'>
                <div className='col-lg-12 fv-row mb-7'>
                  {/* begin::Label */}
                  <label className='required fw-bold fs-6 mb-2'>Select Inventory</label>
                  {/* end::Label */}

                  <Select
                    options={inventory}
                    styles={useSelectStyles()}
                    isDisabled={false}
                    isLoading={inventory_managementisloading}
                    isSearchable={true}
                    ref={selectRef}
                    placeholder={'Select Inventory ...'}
                    //defaultValue={{value: frequentsaleDetails.item_name , label: frequentsaleDetails.item_name}}
                    onChange={(e: any) => handleInventoryChange(e)}
                  />
                </div>
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Product Details</label>

              {/* end::Label */}
              <div className='row'>
                <div className='col-lg-6 fv-row mb-7'>
                  <div className='border border-gray-300 border-dashed rounded py-2 px-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-4 fw-bolder'>
                        {addCommas(quantity)} {packaging_type}
                      </div>
                    </div>

                    <div className='fw-bold fs-7 text-gray-400'>Stock Left</div>
                  </div>
                </div>

                <div className='col-lg-6 fv-row mb-7'>
                  <div className='border border-gray-300 border-dashed rounded py-2 px-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-4 fw-bolder'>
                        {addCommas(Number(unit_landing_cost).toFixed(2))} {CURRENCY_APP}
                      </div>
                    </div>

                    <div className='fw-bold fs-7 text-gray-400'>Buying Price</div>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-lg-6 fv-row mb-7'>
                  <div className='border border-gray-300 border-dashed rounded  py-2 px-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-4 fw-bolder'>
                        {addCommas(Number(unit_selling_price).toFixed(2))} {CURRENCY_APP}
                      </div>
                    </div>

                    <div className='fw-bold fs-7 text-gray-400'>Selling Price</div>
                  </div>
                </div>
                <div className='col-lg-6 fv-row mb-7'>
                  <div className='border border-gray-300 border-dashed rounded py-2 px-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-4 fw-bolder'>
                        {addCommas(Number(sales_tax).toFixed(2))} {CURRENCY_APP}
                      </div>
                    </div>

                    <div className='fw-bold fs-7 text-gray-400'>Sales Tax</div>
                  </div>
                </div>
              </div>

              {/* end::Input group */}

              {/* begin::Input group */}
              <div className='row'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Quantity ({packaging_type})</label>
                {/* end::Label */}

                <div className='col-lg-6 fv-row mb-3'>
                  <input
                    placeholder='0'
                    className='form-control form-control-lg form-control-solid'
                    type='number'
                    min='0'
                    step={`${product_division}`}
                    max={`${quantity}`}
                    autoComplete='off'
                    value={Number(newSetquantity)}
                    disabled={quantity > 0 ? false : true}
                    onChange={(e) => handlequantitychange(e)}
                  />
                </div>
                <div className='col-lg-6 fv-row mb-7'>
                  <div className='align-items-center justify-content-start form-control-sm'>
                    <a
                      onClick={() => {
                        setNewSetquantity((quant: number) => quant - product_division)
                      }}
                      style={{
                        cursor:
                          Number(newSetquantity) - product_division >= 0 &&
                          item_name !== '' &&
                          quantity !== 0
                            ? 'pointer'
                            : 'default',
                        pointerEvents:
                          Number(newSetquantity) - product_division >= 0 &&
                          item_name !== '' &&
                          quantity !== 0
                            ? 'auto'
                            : 'none',
                      }}
                      className={
                        Number(newSetquantity) - product_division >= 0 &&
                        item_name !== '' &&
                        quantity !== 0
                          ? ''
                          : 'pe-none'
                      }
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr014.svg'
                        className={
                          Number(newSetquantity) - product_division >= 0 &&
                          item_name !== '' &&
                          quantity !== 0
                            ? 'svg-icon-2x text-danger'
                            : 'svg-icon-2x'
                        }
                      />
                    </a>
                    <span className='h-20px border-gray-200 border-start mx-1'></span>
                    <a
                      onClick={() => {
                        setNewSetquantity((quant: number) => quant + product_division)
                      }}
                      style={{
                        cursor:
                          Number(newSetquantity) + product_division <= quantity &&
                          item_name !== '' &&
                          quantity !== 0
                            ? 'pointer'
                            : 'default',
                        pointerEvents:
                          Number(newSetquantity) + product_division <= quantity &&
                          item_name !== '' &&
                          quantity !== 0
                            ? 'auto'
                            : 'none',
                      }}
                      className={
                        Number(newSetquantity) + product_division <= quantity &&
                        item_name !== '' &&
                        quantity !== 0
                          ? ''
                          : 'pe-none'
                      }
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr013.svg'
                        className={
                          Number(newSetquantity) + product_division <= quantity &&
                          item_name !== '' &&
                          quantity !== 0
                            ? 'svg-icon-2x text-success'
                            : 'svg-icon-2x'
                        }
                      />
                    </a>
                  </div>
                </div>
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              <div className='row'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Total Price ({CURRENCY_APP})</label>
                {/* end::Label */}

                <div className='col-lg-6 fv-row mb-3'>
                  <input
                    placeholder='0.00'
                    className='form-control form-control-lg form-control-solid'
                    type='number'
                    autoComplete='off'
                    value={parseFloat(newSetprice.toString()).toFixed(2)}
                    disabled={Number(newSetquantity) > 0 ? false : true}
                    onChange={(e) => handlepricechange(e)}
                  />
                </div>
                <div className='col-lg-6 fv-row mb-7'>
                  <div className='align-items-center justify-content-start form-control-sm'>
                    <a
                      onClick={() => {
                        setNewSetprice((price: number) => price - 1)
                      }}
                      style={{
                        cursor:
                          newSetprice - 1 >= 0 && item_name !== '' && quantity !== 0
                            ? 'pointer'
                            : 'default',
                        pointerEvents:
                          newSetprice - 1 >= 0 && item_name !== '' && quantity !== 0
                            ? 'auto'
                            : 'none',
                      }}
                      className={
                        newSetprice - 1 >= 0 && item_name !== '' && quantity !== 0 ? '' : 'pe-none'
                      }
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr014.svg'
                        className={
                          newSetprice - 1 >= 0 && item_name !== '' && quantity !== 0
                            ? 'svg-icon-2x text-danger'
                            : 'svg-icon-2x'
                        }
                      />
                    </a>
                    <span className='h-20px border-gray-200 border-start mx-1'></span>
                    <a
                      onClick={() => {
                        setNewSetprice((price: number) => price + 1)
                      }}
                      style={{
                        cursor:
                          newSetprice > 0 && item_name !== '' && quantity !== 0
                            ? 'pointer'
                            : 'default',
                        pointerEvents:
                          newSetprice > 0 && item_name !== '' && quantity !== 0 ? 'auto' : 'none',
                      }}
                      className={
                        newSetprice > 0 && item_name !== '' && quantity !== 0 ? '' : 'pe-none'
                      }
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr013.svg'
                        className={
                          newSetprice > 0 && item_name !== '' && quantity !== 0
                            ? 'svg-icon-2x text-success'
                            : 'svg-icon-2x'
                        }
                      />
                    </a>
                  </div>
                </div>
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                <div className='d-flex flex-column flex-grow-1'>
                  <div className='d-flex flex-wrap justify-content-end col-12'>
                    <button
                      type='button'
                      className='btn btn-light-danger me-2 mb-2'
                      disabled={addeditemsLoaded}
                      onClick={removeNewitemsTable}
                    >
                      Remove All Items
                    </button>

                    <button
                      type='button'
                      className='btn btn-primary mb-2'
                      onClick={addNewitemsTable}
                      disabled={
                        newSetquantity > 0 && newSetprice > 0 && newSetquantity <= quantity
                          ? false
                          : true
                      }
                    >
                      Add Item
                    </button>
                  </div>
                </div>
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                <div className='card bg-light'>
                  {/* begin::Header */}
                  <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='required card-label fw-bolder fs-3 mb-1'>Added Items</span>
                      <span className='text-muted mt-1 fw-bold fs-7'>
                        {currentItemsmade.length == 1
                          ? `${currentItemsmade.length} entry made`
                          : `${currentItemsmade.length} entries made`}
                      </span>
                    </h3>
                    <div className='card-toolbar'></div>
                  </div>
                  {/* end::Header */}
                  {/* begin::Body */}
                  <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                      {/* begin::Table */}

                      <Mainsubtable4
                        columnaddedtitems={onlyWidth > 500 ? columnaddedtitems : columnaddedtitems2}
                        adddatatable={adddatatable}
                      />

                      {/* end::Table */}
                      <div className='fv-row mt-5 mb-5'>
                        <div className='d-flex flex-column flex-grow-1'>
                          <div className='d-flex justify-content-end'>
                            <div>
                              <div>
                                <div
                                  className='border-top border-dark py-3'
                                  style={{borderBottom: '3px double #000', paddingBottom: '11px'}}
                                >
                                  <span className='fs-5 fw-bolder me-2 mt-3'>
                                    <span className='me-3'>Total:</span>
                                    <span className='text-primary'>
                                      {addCommas(Number(allsubtotalsmade).toFixed(2))}{' '}
                                      {CURRENCY_APP}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end::Table container */}
                  </div>
                  {/* begin::Body */}
                </div>
              </div>
              {/* end::Input group */}
            </form>
          </div>

          <div className='modal-footer border-0 mt-n5'>
            <button type='button' className='btn btn-light-primary' onClick={handleClose}>
              Cancel
            </button>
            <button
              type='submit'
              form='kt_modal_add_inventorypackaging_form'
              className='btn btn-primary'
              onClick={async () => {
                setTableDatanew((currentTableData: any) =>
                  currentTableData.map((row: any) =>
                    row.id === rowIdsub
                      ? {
                          ...row,
                          cost:
                            currentItemsmade.reduce(
                              (acc: any, {unit_landing_cost}: any) => acc + unit_landing_cost,
                              0
                            ) +
                            row.subRows.reduce(
                              (acc: any, {unit_landing_cost}: any) => acc + unit_landing_cost,
                              0
                            ),
                          selling_price:
                            currentItemsmade.reduce(
                              (acc: any, {unit_price}: any) => acc + unit_price,
                              0
                            ) +
                            row.subRows.reduce(
                              (acc: any, {unit_price}: any) => acc + unit_price,
                              0
                            ),
                          total_price:
                            currentItemsmade.reduce(
                              (acc: any, {sub_total}: any) => acc + sub_total,
                              0
                            ) +
                            row.subRows.reduce((acc: any, {sub_total}: any) => acc + sub_total, 0),
                          subRows: [...row.subRows, ...currentItemsmade],
                        }
                      : row
                  )
                )

                handleClose()
              }}
              disabled={adddatatable.length === 0}
            >
              Add Inventory
            </button>
          </div>
        </div>
      </Modal>
    )
  }
)
