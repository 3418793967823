/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
    <AsideMenuItem
      to='/home'
      icon='/media/icons/duotune/art/art002.svg'
      title={"Main Dashboard"}
      fontIcon='bi-app-indicator'
    />
        {/* Project Management */}
        <div className='menu-item'>
      <div className='menu-content pt-8 pb-2'>
        <span className='menu-section text-muted percase fs-8 ls-1'>Enterprise Operations</span>
      </div>
    </div>
        <AsideMenuItemWithSub
  to='#'
  title='Project Management'
  icon='/media/icons/duotune/general/gen006.svg'
  fontIcon='bi-briefcase'
>
  {/* Project Dashboard and Overview */}
  <AsideMenuItem to='/project_management/project_dashboard' title='Project Dashboard'  icon='/media/icons/duotune/general/gen025.svg' fontIcon='bi-stack'/>
  
  {/* Detailed Project Management */}
  <AsideMenuItemWithSub
    to='#'
    title='Project Class'
    icon='/media/icons/duotune/general/gen019.svg'
    fontIcon='bi-stack'
  >
    <AsideMenuItem to='/project_management/project_class/project_categories' title='Project Categories' hasBullet={true} />
    <AsideMenuItem to='/project_management/project_class/project_inventory' title='Project Inventory' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Project Milestones' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Project Tasks' hasBullet={true} />
  </AsideMenuItemWithSub>
</AsideMenuItemWithSub>

    {/* Inventory Management */}
 
    <AsideMenuItemWithSub
  to='#'
  title='Inventory Management'
  icon='/media/icons/duotune/ecommerce/ecm006.svg'
  fontIcon='bi-box-seam'
>
  <AsideMenuItem to='/inventory_management/inventory_dashboard' title='Inventory Dashboard' icon='/media/icons/duotune/general/gen025.svg' fontIcon='bi-stack'/>
  
  <AsideMenuItemWithSub
    to='#'
    title='Inventory Class'
    icon='/media/icons/duotune/ecommerce/ecm008.svg' fontIcon='bi-stack'
  >
    <AsideMenuItem to='/inventory_management/home/inventory_class/inventory_categories' title='Inventory Categories' hasBullet={true} />
    <AsideMenuItem to='/inventory_management/home/inventory_class/inventory_brands' title='Inventory Brands' hasBullet={true} />
    <AsideMenuItem to='/inventory_management/home/inventory_class/inventory_packaging' title='Inventory Packaging' hasBullet={true} />
  </AsideMenuItemWithSub>

  <AsideMenuItemWithSub
    to='#'
    title='Stock Operations'
    icon='/media/icons/duotune/ecommerce/ecm004.svg' fontIcon='bi-stack'
  >
    <AsideMenuItem to='/norights' title='Goods Received Notes' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Inventory Reorder' hasBullet={true} />
    <AsideMenuItem to='/inventory_management/stock_operations/inventory_requests' title='Inventory Requests' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Products/Services' hasBullet={true} />
  </AsideMenuItemWithSub>

  <AsideMenuItemWithSub
    to='#'
    title='Procurement'
    icon='/media/icons/duotune/ecommerce/ecm001.svg'
    fontIcon='bi-stack'
  >
    <AsideMenuItem to='/norights' title='Purchase Orders' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Suppliers' hasBullet={true} />
  </AsideMenuItemWithSub>

  <AsideMenuItemWithSub
    to='#'
    title='Warehouse'
   
    
    icon='/media/icons/duotune/general/gen001.svg' fontIcon='bi-stack'
  >
    <AsideMenuItem to='/norights' title='Stock Movements' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Stores' hasBullet={true} />
  </AsideMenuItemWithSub>
</AsideMenuItemWithSub>


    {/* Financial Management */}
    
   
    <AsideMenuItemWithSub
  to='#'
  title='Financial Management'
  icon='/media/icons/duotune/finance/fin001.svg'
  fontIcon='bi-cash'
>
<AsideMenuItem to='/norights' title='Finance Dashboard' icon='/media/icons/duotune/general/gen025.svg' fontIcon='bi-stack'/>
  {/* Basic Financial Operations */}
  <AsideMenuItemWithSub
    to='#'
    title='Finance Operations'
    icon='/media/icons/duotune/abstract/abs034.svg'
    fontIcon='bi-shuffle'
  >
    <AsideMenuItem to='/norights' title='Accounts Payable' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Accounts Receivable' hasBullet={true} />
    <AsideMenuItem to='/finance_management/finance_operations/expenses' title='Expenses' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Bank Transactions' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Cash Flow' hasBullet={true} />
    <AsideMenuItem to='/financial_management/finance_operations/invoices' title='Invoices' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Payment Details' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Payment Processing' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Purchase Transactions' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Sales Transactions' hasBullet={true} />
  </AsideMenuItemWithSub>

  {/* Credit and Loans */}
  <AsideMenuItemWithSub
    to='#'
    title='Credit & Loans'
    icon='/media/icons/duotune/finance/fin008.svg'
    fontIcon='bi-credit-card-2-back-fill'
  >
    <AsideMenuItem to='/norights' title='Credit Applications' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Credit Control' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Credit Control Aging' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Loans' hasBullet={true} />
  </AsideMenuItemWithSub>

  {/* Reporting and Analysis */}
  <AsideMenuItemWithSub
    to='#'
    title='Reporting & Analysis'
    icon='/media/icons/duotune/general/gen026.svg'
    fontIcon='bi-graph-up'
  >
    <AsideMenuItem to='/norights' title='Financial Reporting' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Supplier Aging' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Demand Notices' hasBullet={true} />
  </AsideMenuItemWithSub>

  {/* Assets and Liabilities */}
  <AsideMenuItemWithSub
    to='#'
    title='Assets & Liabilities'
    icon='/media/icons/duotune/finance/fin005.svg'
    fontIcon='bi-bank'
  >
    <AsideMenuItem to='/norights' title='Fixed Assets' hasBullet={true} />
    <AsideMenuItem to='/norights' title='General Ledger' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Bank Accounts' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Budgets' hasBullet={true} />
  </AsideMenuItemWithSub>

  {/* Taxes */}
  <AsideMenuItemWithSub
    to='#'
    title='Taxes'
    icon='/media/icons/duotune/finance/fin007.svg'
    fontIcon='bi-receipt-cutoff'
  >
    <AsideMenuItem to='/norights' title='Tax Details' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Tax Liabilities' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Tax Transactions' hasBullet={true} />
  </AsideMenuItemWithSub>
</AsideMenuItemWithSub>


<AsideMenuItemWithSub
    to='#'
    title='CS Management'
    icon='/media/icons/duotune/abstract/abs025.svg'
    fontIcon='bi-people-fill'
  >
    <AsideMenuItem
  to='/norights'
  icon='/media/icons/duotune/general/gen025.svg'
  title='CS Dashboard'
  fontIcon='bi-speedometer'
/>
<AsideMenuItemWithSub
    to='#'
    title='CS Relations'
    icon='/media/icons/duotune/abstract/abs026.svg'
    fontIcon='bi-people-fill'
  >
    <AsideMenuItem to='/cs_management/cs_relations/clients' title='Clients' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Client Interaction Log' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Suppliers' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Supplier Contracts' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Supplier Interaction Log' hasBullet={true} />
  </AsideMenuItemWithSub>
  </AsideMenuItemWithSub>

    {/* Human Resources */}

    <AsideMenuItemWithSub
      to='#'
      title='HR Management'
      icon='/media/icons/duotune/general/gen013.svg'
      fontIcon='bi-people'
    >
       <AsideMenuItem
    to='/hr-dashboard'
    title='HR Dashboard'
    icon='/media/icons/duotune/general/gen025.svg'
    fontIcon='bi-speedometer'
  />
  <AsideMenuItemWithSub
      to='#'
      title='HR Details'
      icon='/media/icons/duotune/communication/com006.svg'
      fontIcon='bi-people'
    >
      <AsideMenuItem to='/norights' title='Departments' hasBullet={true} />
      <AsideMenuItem to='/norights' title='Employee Salaries' hasBullet={true} />
      <AsideMenuItem to='/norights' title='Employee Benefits' hasBullet={true} />
   <AsideMenuItem to='/norights' title='Reimbursements' hasBullet={true} />
   <AsideMenuItem to='/norights' title='Employees' hasBullet={true} />
   <AsideMenuItem to='/norights' title='Leave Requests' hasBullet={true} />
   </AsideMenuItemWithSub>
      {/* Add more HR items here */}
    </AsideMenuItemWithSub>

    <>
  {/* Client and Supplier Relations Header */}
  
  {/* Client and Supplier Relations Menu Items */}
  <div className='menu-item'>
      <div className='menu-content pt-8 pb-2'>
        <span className='menu-section text-muted percase fs-8 ls-1'>Support Functions</span>
      </div>
    </div>
  
  {/* Sales and Marketing Header */}

  {/* Sales and Marketing Menu Items */}
  <AsideMenuItemWithSub
    to='#'
    title='Sales & Marketing'
    icon='/media/icons/duotune/general/gen005.svg'
    fontIcon='bi-graph-up'
  >
     <AsideMenuItem
  to='/norights'
  icon='/media/icons/duotune/general/gen025.svg'
  title='SM Dashboard'
  fontIcon='bi-speedometer'
/>
<AsideMenuItemWithSub
    to='#'
    title='SM Operations'
    icon='/media/icons/duotune/abstract/abs021.svg'
    fontIcon='bi-graph-up'
  >
    <AsideMenuItemWithSub
    to='#'
    title='SM Operations'
    icon='/media/icons/duotune/abstract/abs021.svg'
    fontIcon='bi-graph-up'
  >
    <AsideMenuItem to='/sales_marketing/sm_operations/createquotation' title='Create Quotation' hasBullet={true} />
    <AsideMenuItem to='/sales_marketing/sm_operations/quotations' title='View Quotations' hasBullet={true} />
    </AsideMenuItemWithSub>
    <AsideMenuItem to='/norights' title='Sales Transactions' hasBullet={true} />
  </AsideMenuItemWithSub>
  </AsideMenuItemWithSub>

  {/* Compliance and Audit Header */}

  {/* Compliance and Audit Menu Items */}
  <AsideMenuItemWithSub
    to='#'
    title='Compliance & Audit'
    icon='/media/icons/duotune/general/gen003.svg'
    fontIcon='bi-shield-lock'
  >
    <AsideMenuItem
  to='/norights'
  icon='/media/icons/duotune/general/gen025.svg'
  title='Compliance Dashboard'
  fontIcon='bi-speedometer'
/>
<AsideMenuItemWithSub
    to='#'
    title='Compliance & Audit'
    icon='/media/icons/duotune/general/gen026.svg'
    fontIcon='bi-shield-lock'
  >
    <AsideMenuItem to='/norights' title='Audit Trail' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Compliance Documents' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Regulatory Compliance' hasBullet={true} />
  </AsideMenuItemWithSub>
  </AsideMenuItemWithSub>
</>

   

    {/* Reports */}
   
  
    <AsideMenuItemWithSub
      to='#'
      title='Reports'
      icon='/media/icons/duotune/general/gen032.svg'
      fontIcon='bi-file-earmark-text'
    >
        <AsideMenuItem
  to='/norights'
  icon='/media/icons/duotune/general/gen025.svg'
  title='Reports Dashboard'
  fontIcon='bi-speedometer'
/>
<AsideMenuItemWithSub
      to='#'
      title='Reports'
      icon='/media/icons/duotune/general/gen005.svg'
      fontIcon='bi-file-earmark-text'
    >
      <AsideMenuItem to='/norights' title='Financial Reports' hasBullet={true} />
      <AsideMenuItem to='/norights' title='Inventory Reports' hasBullet={true} />
      <AsideMenuItem to='/norights' title='Sales Reports' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Purchase Reports' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Employee Reports' hasBullet={true} />
    <AsideMenuItem to='/norights' title='Project Reports' hasBullet={true} />
      {/* Add more report items here */}
    </AsideMenuItemWithSub>
    </AsideMenuItemWithSub>

     {/* System Administration */}
     <div className='menu-item'>
      <div className='menu-content pt-8 pb-2'>
        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>System Settings</span>
      </div>
    </div>
     <AsideMenuItemWithSub
      to='#'
      title='System Administration'
      icon='/media/icons/duotune/coding/cod001.svg'
      fontIcon='bi-gear'
    >
        <AsideMenuItem
  to='/norights'
  icon='/media/icons/duotune/general/gen025.svg'
  title='Admin Dashboard'
  fontIcon='bi-speedometer'
/>
<AsideMenuItemWithSub
      to='#'
      title='User Administration'
      icon='/media/icons/duotune/general/gen051.svg'
      fontIcon='bi-gear'
    >
      <AsideMenuItem to='/norights' title='User Access Roles' hasBullet={true} />
      <AsideMenuItem to='/norights' title='Users' hasBullet={true} />
      <AsideMenuItem to='/norights' title='User Designation' hasBullet={true} />
    </AsideMenuItemWithSub>
    </AsideMenuItemWithSub>
  </>
  )
}
