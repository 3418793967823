import { useQuery, useMutation } from '@tanstack/react-query';
import { getCategories, createCategories, deleteCategories, updateCategories } from '../../apis/inventory_management/Loadallcategories'; // Adjust import path as necessary

export const useCategories = (
  start_limit: number,
  pagination_size: number,
  itemto_order: string,
  desorasc: string,
  search_term: string,
  user_role_id: number,
  user_designation: number
) => useQuery({
  queryKey: ['inventorycategories', start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id, user_designation],
  queryFn: () => getCategories(start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id, user_designation),
  placeholderData: (previousData: any) => previousData,
  staleTime: 5 * 60 * 1000,
});

export const useAddCategory = (categoryAdded: any) => useMutation({
  mutationFn: () => createCategories(categoryAdded)
});

export const useDeleteCategory = (categoryToDelete: number) => useMutation({
  mutationFn: () => deleteCategories(categoryToDelete)
});

export const useUpdateCategory = (categoryToUpdate: any) => useMutation({
  mutationFn: () => updateCategories(categoryToUpdate)
});
