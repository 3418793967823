import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const API_URL2 = process.env.REACT_APP_API_URL_SOCKET

// Categories
export function getCategories(start_limit: number, pagination_size: number, itemto_order: string, desorasc: string, search_term: string, user_role_id: number, user_designation: number) {
  return axios.get(`${API_URL}/categories_get`, { params: {start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id, user_designation}});
}

export function createCategories(categoriesadded: any) {
  return axios.post(`${API_URL}/categories_add`, categoriesadded);
}

export function deleteCategories(category_id: number) {
  return axios.delete(`${API_URL}/categories_delete`, {params: {category_id}});
}

export function updateCategories(categoriestoupdate: any) {
  return axios.put(`${API_URL}/categories_update`, categoriestoupdate);
}