import { useState, useMemo } from 'react';
import Select from 'react-select';
import { ThemeModeType, useThemeMode } from '../../_metronic/partials';

export const useSelectStyles = () => {

  const {mode} = useThemeMode()
  const customStyles: any = useMemo(() => ({
    control: (provided: any, state: any) => ({
      ...provided,
      borderColor: mode === 'dark'? '#1b1b29' :'#f5f8fa',
      boxSizing: 'border-box',
      fontSize: '1.1em',
      height: '45px',
      backgroundColor: mode === 'dark'? '#1b1b29' : '#eef3f7',
      color: mode === 'dark' ? '#f5f5f5' : null,
      fontWeight: '500!important',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      borderColor: mode === 'dark'? '#1b1b29':'#eef3f7',
      borderTopColor: mode === 'dark' ? '#1b1b29' : null,
      borderBottomColor: mode === 'dark' ? '#1b1b29' : null,
      fontSize: '1.1em',
      fontWeight: '500!important',
      color: state.isSelected ? '#f5f5f5' : mode === 'dark' ? '#92929f' : null,
      '&:hover': {
        backgroundColor: state.isSelected ? '#2684FF' : (mode === 'dark' ? '#009ef7' : '#deebff'),
        color: mode === 'dark'? '#f5f5f5' : null,
      },
      backgroundColor: state.isSelected ? '#2684FF' : state.isFocused && mode === 'dark' ? '#1b1b29' : (mode === 'dark' ? '#1b1b29' : null),
    
    }),
    singleValue: (provided: any, state: any) => ({
        ...provided,
        color: mode === 'dark' ? '#f5f5f5' : null,
      }),

      input: (provided: any) => ({
        ...provided,
        color: mode === 'dark' ? '#f5f5f5' : null,
      }),
      
      

  }), [mode]);

  

  return customStyles;
};