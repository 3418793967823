import {KTSVG} from '../../../../../_metronic/helpers'
import React, {FC, forwardRef, useEffect, useState} from 'react'
import { Mainsubtable } from '../../../../tables/Mainsubtable'
import {Modal} from 'react-bootstrap'
import {Column} from 'react-table'
import {useWindowWidth} from '@react-hook/window-size/throttled'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { Mainsubtable3 } from '../../../../tables/Mainsubtable3'
import { createColumnHelper } from '@tanstack/react-table'
import { useDeleteCategory } from '../../../../contextProviders/inventory_management/Inventorycategoriesprovider'
import { useDeleteBrand } from '../../../../contextProviders/inventory_management/Inventorybrandsprovider'



dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(localizedFormat)


type Props = {

  show: boolean
  handleClose: () => void
  inventorybrandsitemtodelete: any


}

const addCommas = (num: any) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
const CURRENCY_APP = process.env.REACT_APP_CURRENCY
const INFINITE_ROWS = process.env.REACT_APP_INFINITE_ROWS
const OPENING_TIME = process.env.REACT_APP_OPENING_TIME

export const DeleteInventoryBrands: FC<Props> = forwardRef((props, ref) => {
 
  const truncate = require('truncate')
  const onlyWidth = useWindowWidth()
  const columnHelper = createColumnHelper<any>();
  const [loading, setLoading] = useState<boolean>(false)
  const columndata2 = [
    columnHelper.accessor("brand_id", {
      header: "id",
      cell: (info: any) =>  <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>{info.getValue()}</span>,
    }),
    columnHelper.accessor("brand_name", {
      header: "Name",
      cell: (info: any) => <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6'>{info.getValue()}</span>,
    }),
  ];
 
  const {mutateAsync: deleteinventorybrandmutateAsync} = useDeleteBrand(
    props.inventorybrandsitemtodelete.brand_id
  )

  const deleteInventroy = async () => {

    setLoading(true)
    deleteinventorybrandmutateAsync(props.inventorybrandsitemtodelete.brand_id, {
      
      onSuccess: () => {
        setLoading(false)
        props.handleClose()
      },
      onError(error, _variables, _context) {
        console.log(error)
        setLoading(false)
        
      },
    })
  }


  return (
    <Modal
      className='modal fade'
      id='kt_modal_dashboard'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={props.show}
      centered
      dialogClassName='modal-lg'
      aria-hidden='true'
      onHide={props.handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h3 className='fw-bolder'>Delete Inventory Brand Item
          <span className='h-10px border-gray-200 border-start mx-2'></span>

           <span className='text-muted fw-bold fs-6'>{props.inventorybrandsitemtodelete.brand_id}</span>
          
          </h3>
          

          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={props.handleClose}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>
        <div className='modal-body'>
        <div className='fv-row m-5'>
              <div className='card bg-light p-10'>
              <Mainsubtable3
                        tabledata2={[props.inventorybrandsitemtodelete]}
                        columndata2={columndata2}
                        
                       
                      />

              </div>
            </div>

          <span className='required fw-bold fs-5 m-5'>
            Are you sure you want to delete the item above?
          </span>

        </div>
        <div className='modal-footer border-0 mt-n5'>
          <button type='button' className='btn btn-light-primary' onClick={props.handleClose}>
            Cancel
          </button>
          <button
            id='submit'
            type='button'
            className='btn btn-danger'
            onClick={deleteInventroy}
           
          >
            {!loading && <span className='indicator-label'>Delete Inventory Brand</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
})
