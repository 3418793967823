import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

// Inventory Requests
export function getInventoryRequests(start_limit: number, pagination_size: number, itemto_order: string, desorasc: string, search_term: string, user_role_id: number, user_designation: number, entity_filter?: number) {
  return axios.get(`${API_URL}/inventory_requests_get`, {
    params: {
      start_limit,
      pagination_size,
      itemto_order,
      desorasc,
      search_term,
      user_role_id,
      user_designation,
      entity_filter
    },
  });
}

export function createInventoryRequest(inventoryRequestData: any) {
  return axios.post(`${API_URL}/inventory_requests_add`, inventoryRequestData);
}

export function updateInventoryRequest(inventoryRequestData: any) {
  return axios.put(`${API_URL}/inventory_requests_update`, inventoryRequestData);
}

export function deleteInventoryRequest(request_id: number) {
  return axios.delete(`${API_URL}/inventory_requests_delete`, { params: { request_id } });
}
