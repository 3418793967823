import { useQuery, useMutation } from '@tanstack/react-query';
import { createQuotation, deleteQuotation, getQuotations, updateQuotation } from '../../apis/sales_marketing/Loadallquotations';

// Use Quotations
export const useQuotations = (
  start_limit: number,
  pagination_size: number,
  itemto_order: string,
  desorasc: string,
  search_term: string,
  user_role_id: number,
  user_designation: number
) => useQuery({
  queryKey: ['quotations', start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id, user_designation],
  queryFn: () => getQuotations(start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id, user_designation),
  staleTime: 5 * 60 * 1000, // 5 minutes
});

// Add Quotation
export const useAddQuotation = (quotationAdded: any) => useMutation({
  mutationFn: () => createQuotation(quotationAdded)
});

// Delete Quotation
export const useDeleteQuotation = (quotationToDelete: number) => useMutation({
  mutationFn: () => deleteQuotation(quotationToDelete)
});

// Update Quotation
export const useUpdateQuotation = (quotationToUpdate: any) => useMutation({
  mutationFn: () => updateQuotation(quotationToUpdate)
});
